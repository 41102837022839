import dayjs, { Dayjs } from 'dayjs';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';

import { basicInitialState } from '../_utils/basicInitialState';
import { buildFields } from 'other/formAndValidation/formUtils';
import { TPromoState } from './promoModel';

/**/
dayjs.extend(pluralGetSet);

/**/
export const today: Dayjs = dayjs().add(1, 'hour').minutes(0);
export const endDate: Dayjs = dayjs().clone().add(1, 'week');

const keys = [
  'countries',
  'end',
  'serviceCategories',
  'start',
  'utcOffset',
  'vesselTypes'
];
const values = {
  countries: [],
  end: endDate,
  serviceCategories: null,
  start: today,
  utcOffset: '+01:00',
  vesselTypes: null
};
export const promoInitialFields = buildFields(keys, values);

export const promoInitialState: TPromoState = {
  ...basicInitialState,
  articles: [],
  cost: null,
  isCalculating: false,
  fields: promoInitialFields,
  report: null
};
