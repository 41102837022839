import { basicInitialState } from '../../_utils/basicInitialState';
import { SettingsService } from 'services/settings';

import { ETrackPeriod } from 'types';
import { TTracksState } from './tracksModel';

export const tracksInitialState: TTracksState = {
  ...basicInitialState,
  trackPeriod:
    SettingsService.readSettings()[SettingsService.TRACK_PERIOD] ||
    ETrackPeriod.DAYS_3,
  trackPoints: [],
  tracks: [],
  vectors: []
};
