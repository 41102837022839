import createAsyncActions from '../_utils/createAsyncActions';
import { endDate, today } from '../promo/promoInitialState';

import { EPromotedType } from 'types';
import { TCampaignState } from './campaignModel';

export enum ECampaignActions {
  CREATE_CAMPAIGN = 'campaign/CREATE_CAMPAIGN',
  REMOVE_CAMPAIGN = 'campaign/REMOVE_CAMPAIGN',
  SUBMIT_CAMPAIGN = 'campaign/SUBMIT_CAMPAIGN',
  BANNER_UPLOAD = 'campaign/BANNER_UPLOAD',
  CALCULATE_COST = 'campaign/CALCULATE_COST',
  FETCH_ARTICLES = 'campaign/FETCH_ARTICLES',
  FETCH_PRODUCTS = 'campaign/FETCH_PRODUCTS',
  FETCH_PAYMENT_REPORT = 'campaign/FETCH_PAYMENT_REPORT',

  ASSIGN_CAMPAIGNS = 'campaign/ASSIGN_CAMPAIGNS',
  BANNER_UPLOAD_PROGRESS = 'campaign/BANNER_UPLOAD_PROGRESS',
  UPDATE_FIELD = 'campaign/UPDATE_FIELD',
  CLEAR_CAMPAIGN = 'campaign/CLEAR_CAMPAIGN',
  CLEAR_REPORT = 'campaign/CLEAR_REPORT',
  SET_FORM_STEP = 'campaign/SET_FORM_STEP',
  ALLOW_FORM_STEP = 'campaign/SET_FORM_STEP'
}

export const calcSet = createAsyncActions<TCampaignState>(
  ECampaignActions.CALCULATE_COST
);
export const createSet = createAsyncActions<TCampaignState>(
  ECampaignActions.CREATE_CAMPAIGN
);
export const fetchArticles = createAsyncActions<TCampaignState>(
  ECampaignActions.FETCH_ARTICLES
);
export const fetchProducts = createAsyncActions<TCampaignState>(
  ECampaignActions.FETCH_PRODUCTS
);
export const fetchReportSet = createAsyncActions<TCampaignState>(
  ECampaignActions.FETCH_PAYMENT_REPORT
);
export const removeSet = createAsyncActions<TCampaignState>(
  ECampaignActions.REMOVE_CAMPAIGN
);
export const submitSet = createAsyncActions<TCampaignState>(
  ECampaignActions.SUBMIT_CAMPAIGN
);
export const uploadSet = createAsyncActions<TCampaignState>(
  ECampaignActions.UPDATE_FIELD
);

export const campaignBlueprint = {
  end: endDate,
  start: today,
  type: EPromotedType.BANNER
};
