import React, { Children, ReactElement } from 'react';
import Carousel from 'antd/lib/carousel';
import classnames from 'classnames';
import './CarouselCustom.scss';

type Props = {
  autoplay?: boolean;
  autoplaySpeed?: number;
  buttons?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
  effect?: 'scrollx' | 'fade';
  dots?: boolean;
  shadow?: boolean;
  speed?: number;
};

class CarouselCustom extends React.PureComponent<Props> {
  public carousel: any;
  static defaultProps;

  next = () => this.carousel.next();
  previous = () => this.carousel.prev();
  assignCarousel = (node) => (this.carousel = node);

  render() {
    const { buttons, children, className, shadow, ...props } = this.props;
    const shouldShowButtons = Children.count(children) > 1 && buttons;

    const cls = classnames('CarouselCustom', className, {
      'CarouselCustom--shadow': shadow
    });

    return (
      <div className={cls}>
        {shouldShowButtons && (
          <button
            className="CarouselCustom__prev"
            type="button"
            onClick={this.previous}
          >
            &lt;
          </button>
        )}

        <Carousel ref={this.assignCarousel} {...props}>
          {children}
        </Carousel>

        {shouldShowButtons && (
          <button
            className="CarouselCustom__next"
            type="button"
            onClick={this.next}
          >
            &gt;
          </button>
        )}
      </div>
    );
  }
}

CarouselCustom.defaultProps = {
  autoplay: true,
  autoplaySpeed: 7000,
  buttons: true,
  dots: true,
  effect: 'scrollx',
  shadow: false,
  speed: 500
};

export { CarouselCustom };
