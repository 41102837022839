import PropTypes from 'prop-types';
import { TIdName } from './general';

export type TFleet = TIdName & {
  companyIds: number[];
  fishFarmIds: number[];
  vesselIds: number[];
};

export type TFleetItems = {
  companies: number[];
  farms: number[];
  vessels: number[];
};

export const PTFleet = {
  companyIds: PropTypes.arrayOf(PropTypes.number),
  fishFarmIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vesselIds: PropTypes.arrayOf(PropTypes.number).isRequired
};
