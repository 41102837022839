import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './NotificationListItem.scss';
import { getNotificationObject } from './helpers';
import { TNotification } from 'types';

/**/
type Props = {
  notification: TNotification;
  onItemClick: (id: number) => void;
};

// const ntf = {
//   date: '2024-05-22T14:53:22.969Z',
//   id: 321,
//   read: false,
//   type: 'PROMOTION',
//   body: JSON.stringify({
//     articleId: 17211,
//     articleName: 'Skal du fiske i saltvann eller ferskvann?',
//     sourceId: 85,
//     sourceName: 'FF QA Service Provider',
//     sourceLogo: {
//       path: '85/logo/ClintEastwood 3-92233abffd9d153c31e116bb7c663c5a.jpg'
//     }
//   })
// };
// const ntf = {
//   id: 1,
//   body: JSON.stringify({
//     taggedEntityId: 1523,
//     taggedEntityName: 'Test Vessel',
//     taggedEntityType: 'VESSEL',
//     taggedInId: 270,
//     taggedInName: 'test title',
//     taggedInType: 'NEWS',
//     taggedById: 102,
//     taggedByName: 'test name',
//     taggedByType: 'SERVICE_PROVIDER',
//     taggedByLogo: '85/logo/ClintEastwood 3-92233abffd9d153c31e116bb7c663c5a.jpg'
//     // taggedByLogo: '102/logo/yacht-3dd468583468256594d8284468ced12e.jpg'
//   }),
//   type: 'TAGGED_ENTITY',
//   createdAt: '2024-09-02T08:41:16',
//   read: false
// } as any;

/**
 *
 */
function NotificationListItem({ notification, onItemClick }: Props) {
  const { id, read, type } = notification;
  const obj = new (getNotificationObject(type))(notification);
  const handleClick = () => onItemClick(id);

  const cls = classnames('NotificationListItem', {
    'NotificationListItem--read': read
  });

  return (
    <Link className={cls} onClick={handleClick} to={obj.getPath()}>
      <div className="NotificationListItem__background" />

      <div className="NotificationListItem__content">
        <div className="NotificationListItem__prefix">{obj.getIcon()}</div>

        <div>
          <div className="app-body-2 NotificationListItem__text">
            {obj.getContent()}
          </div>

          <div className="app-caption NotificationListItem__date">
            {obj.getDate()}
          </div>
        </div>
      </div>
    </Link>
  );
}

/**/
export { NotificationListItem };
