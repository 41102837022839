import { TEngine } from '../dictionaries';

export type TPropulsionAndManeuvering = {
  mainPowerDriveMethod?: EPowerDrive;
  mainEngines?: TEngine & {
    generator?: TEngine;
    quantity: number;
  };
  auxiliaryEngines?: THybridEngine[];
  electricalMotors: Array<
    TEngine & {
      quantity: number;
    }
  >;
  propellers?: TPropeller & {
    quantity: number;
  };
  steeringGear?: {
    details: string;
    manufacturerId: number;
  };
  steeringRudder?: {
    details: string;
    manufacturerId: number;
  };
  reductionGear?: TReductionGear;
  propulsionThrusters?: TPropulsionThruster & {
    quantity: number;
  };
  maneuveringThrusters?: TManeuveringThruster & {
    quantity: number;
  };
  dynamicPositioningAvailable?: boolean;
  dynamicPositioningManufacturer: number;
};

export type THybridEngine = TEngine & {
  generator?: TEngine;
  quantity: number;
};

export enum EPowerDrive {
  DIESEL_MECHANICAL = 'Diesel Mechanical',
  DIESEL_ELECTRICAL = 'Diesel Electric',
  DIESEL_HYBRID = 'Diesel Hybrid',
  DUAL_FUEL = 'Dual Fuel (DF)',
  BATTERY_HYBRID = 'Battery Hybrid',
  BATTERY_ELECTRIC = 'Battery Electric',
  GAS = 'LNG Electric'
}

export type TPropeller = {
  details: string;
  pitch: EPitch;
  manufacturerId: number;
};
export enum EPitch {
  CONTROLLABLE = 'Controllable Pitch',
  FIXED = 'Fixed Pitch'
}
export type TReductionGear = {
  type: EReductionGear;
  details: string;
  manufacturerId: number;
};

export enum EReductionGear {
  PTO = 'PTO',
  PTI = 'PTI',
  PTO_PTI = 'PTO/PTI',
  PTO_PTH = 'PTO/PTH',
  PTI_PTO_PTH = 'PTI/PTO/PTH',
  GEARLESS = 'Gearless'
}
export type TPropulsionThruster = {
  details: string;
  type: EPropulsionThruster;
  power: number;
  manufacturerId: number;
  quantity: number;
};

export enum EPropulsionThruster {
  PROP_THRUST = 'Propulsion Thruster',
  AZIMUTH = 'Azimuth',
  AZI_PODS = 'Azi Pods',
  RETRACTABLE_AZIMUTH = 'Retractable Azimuth'
}

export type TManeuveringThruster = {
  details: string;
  type: EManeuveringThruster;
  power: number;
  manufacturerId: number;
  quantity: number;
};

export enum EManeuveringThruster {
  STERN = 'Stern Thruster',
  BOW = 'Bow Thruster',
  STERN_BOW = 'Stern/Bow',
  AZIMUTH = 'Azimuth',
  TUNNEL = 'Tunnel Thrusters'
}
