import PropTypes from 'prop-types';

import { PTCountryISO, TCountryISO } from './dictionaries';
import {
  PTIdLocationDict,
  PTLocaleSet,
  TIdLocationDict,
  TIdName
} from './general';
import { PTOwnerShort, TOwnerShort } from './owner';
import { PTVesselPhotoSet } from './vessel';
import { TFarmLicence } from './license';
import { TMedia, TPhotoSet } from './media';
import { EMiniCardPanel, TMapEntityState } from './map';

export type TFarm = {
  id: number;
  timeSeries: TTimeSeries[];
  country: string;
  groups: TOwnerShort[];
  licences: TFarmLicence[];
  liceTreatments: TLiceTreatment[];
  name: string;
  number: number;
  owners: TOwnerShort[];
  photo: TPhotoSet;
  registrationNumber: string;
  species: TIdLocationDict[];
  type: TIdLocationDict;
};

export type TFarmOfCompany = {
  country?: TCountryISO;
  fishFarmId: number;
  mediaPath?: string;
  name: string;
  registrationNumber?: string;
};

export const PTFarmOfCompany = {
  country: PropTypes.exact(PTCountryISO),
  fishFarmId: PropTypes.number.isRequired,
  mediaPath: PropTypes.string,
  name: PropTypes.string.isRequired,
  registrationNumber: PropTypes.string
};

export type TFarmLocation = {
  id: number;
  country: string;
  latitude: number;
  longitude: number;
  licenseHolder: TIdName;
  licenseRegistrationNumber: string;
  name: string;
  photo: TMedia;
  species: TIdLocationDict[];
  type: TIdLocationDict;
};
export type TFarmLocationExt = TFarmLocation & {
  uiState?: TMapEntityState;
};

export type TFarmArea = {
  type: 'Feature';
  properties: {
    fishfarm: {
      country: string;
      fishFarmOwner: TOwnerShort;
      id: number;
      licenseHolder: TIdName;
      photo: TMedia;
      number: string;
      name: string;
      status: TIdLocationDict;
      type?: TIdLocationDict;
    };
  };
  geometry: {
    type: 'Polygon';
    coordinates: Array<Array<[number, number]>>;
  };
};

export type TTimeSeries = {
  name: string;
  value: number;
  measurementUnits: string;
  lastUpdate: string;
};

export enum EFarm {
  FACILITY = 'Facility',
  FISHFARMS = 'Fish farm',
  SMOLT = 'Smolt',
  BROOD = 'Brood'
}

export type TLiceTreatment = {
  activeIngredient: string;
  drug: string;
  end: string;
  measurementUnit: TIdLocationDict;
  start: string;
  value: number;
};

export const PTLiceTreatment = {
  activeIngredient: PropTypes.string.isRequired,
  drug: PropTypes.string,
  end: PropTypes.string.isRequired,
  measurementUnit: PropTypes.exact({
    id: PropTypes.number,
    value: PropTypes.exact(PTLocaleSet)
  }),
  start: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export const PTFarm = {
  country: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.exact(PTOwnerShort)),
  id: PropTypes.number.isRequired,
  liceTreatments: PropTypes.arrayOf(PropTypes.exact(PTLiceTreatment)),
  name: PropTypes.string.isRequired,
  owners: PropTypes.arrayOf(PropTypes.exact(PTOwnerShort)),
  photo: PropTypes.exact(PTVesselPhotoSet),
  registrationNumber: PropTypes.string,
  type: PropTypes.exact(PTIdLocationDict).isRequired
};
