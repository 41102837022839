import { basicInitialState } from 'store/_utils/basicInitialState';
import { TVesselsComparatorState } from './vesselsComparatorModel';

export const vesselsComparatorInitialState: TVesselsComparatorState = {
  ...basicInitialState,
  vesselA: null,
  vesselB: null,
  vesselAData: null,
  vesselBData: null
};
