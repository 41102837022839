import { Rule } from 'antd/lib/form';

/**
 * Returns a set of validation rules associated with number og characters.
 * When 'min' is defined, the 'required' and 'empty' rules are on.
 * @param fieldName
 * @param max
 * @param min
 * @param isRequired
 */
export function minMaxLenValidator(
  fieldName: string,
  max: number,
  min?: number,
  isRequired = true
): Rule[] {
  const maxRule: Rule = {
    max: max,
    message: `${fieldName} can't be longer than ${max} characters!`
  };

  if (min === void 0) return [maxRule];

  return [
    maxRule,
    {
      required: isRequired,
      message: `${fieldName} is required!`
    },
    {
      min: min,
      message: `${fieldName} must be at least ${min} characters long!`
    },
    {
      whitespace: true,
      message: `${fieldName} can't be empty!`
    }
  ];
}
