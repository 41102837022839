export const lightCommonVariables = {
  token: {
    colorBgContainer: '#fff',
    colorText: '#4E5B6A'
  },
  components: {
    Button: {
      colorBorder: '#f1f1f0'
    },
    Checkbox: {
      colorBgContainer: '#fff'
    },
    // FormItem: {
    // marginBottom: '24px;'
    // },
    Collapse: {
      colorTextHeading: '#000',
      collapseContentBg: '#fff' // doesn't take effect
    },
    ColorPicker: {
      colorBorder: '#E7E7E6' // --button-default-border
    },
    DatePicker: {
      colorBorder: '#E7E7E6',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25)'
    },
    Drawer: {
      colorBgElevated: '#fff'
    },
    Form: {
      colorTextHeading: '#000'
    },
    Input: {
      // colorBgContainer: '#23273a', // (--body-background)
      colorBorder: '#E7E7E6', // --button-default-border
      colorText: '#4E5B6A'

      // inputBorderActiveColor: '#ff8b6b', // (--primary-color-light)
      // inputBorderHoverColor: '#ff8b6b', // (--primary-color-light)
    },
    InputNumber: {
      colorBorder: '#E7E7E6'
    },
    Modal: {
      colorTextHeading: '#000', // doesn't take effect
      modalContentBg: '#fff' // doesn't take effect
    },
    Radio: {
      colorBorder: '#E7E7E6'
    },
    Select: {
      colorBorder: '#E7E7E6',
      colorText: '#4E5B6A',
      controlItemBgActive: '#f1f1f1'
    },
    Slider: {
      colorText: '#4E5B6A'
    },
    Switch: {
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)'
    },
    // Table: {
    //   tableFooterBg: '#fff',
    //   tableHeaderBg: '',
    //   tableHeaderTextColor: '',
    //   tablePaddingVertical: '',
    // tablePaddingHorizontal: '',
    // },
    Tabs: {
      colorSplit: '#E7E7E6',
      tabsCardHeadBackground: '#fafafa !important' // doesn't take effect
    },
    Tag: {
      tagDefaultColor: '#4E5B6A'
    },
    Tooltip: {
      // tooltipBg: 'rgba(0, 0, 0, 0.75)'
    }
  }
};
