import React, { Component, ComponentType } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from './helpers/helpers';

export type TInjectedModalStateProps = {
  isModalVisible: boolean;
  onModalHide: () => void;
  onModalShow: () => void;
};

export const PTInjectedModalStateProps = {
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onModalShow: PropTypes.func.isRequired
};

type State = {
  isModalVisible: boolean;
};

/**
 * HOC for holding modal state
 * @param WrappedComponent
 * @param isModalVisible
 */
const withModalState = <P extends TInjectedModalStateProps>(
  WrappedComponent: ComponentType<P>,
  isModalVisible = false
): ComponentType<Omit<P, keyof TInjectedModalStateProps>> =>
  class HOC extends Component<Omit<P, keyof TInjectedModalStateProps>, State> {
    static displayName = `withModalState(${getDisplayName(WrappedComponent)})`;

    state: State = {
      isModalVisible: isModalVisible
    };

    handleModalShow = (): void => this.setState({ isModalVisible: true });

    handleModalHide = (): void => this.setState({ isModalVisible: false });

    render() {
      const updatedProps = {
        ...(this.props as P),
        isModalVisible: this.state.isModalVisible,
        onModalHide: this.handleModalHide,
        onModalShow: this.handleModalShow
      };

      return <WrappedComponent {...updatedProps} />;
    }
  };

export { withModalState };
