import AdminNotificationObject from './AdminNotificationObject';
import MentionNotificationObject from './MentionNotificationObject';
import PromoNotificationObject from './PromoNotificationObject';

import { ENotification } from 'types';

/**/
export type TPromotionNotification = {
  articleId: number;
  articleName: string;
  sourceId: number;
  sourceName: string;
  sourceLogo: {
    path: string;
  };
};

/**/
export type TMentionNotification = {
  taggedEntityId: number;
  taggedEntityName: string;
  taggedEntityType: EMentioned;
  taggedInId: number;
  taggedInName: string;
  taggedInType: EMentionSubject;
  taggedById: number;
  taggedByName: string;
  taggedByType: 'SERVICE_PROVIDER' | 'USER';
  taggedByLogo: string;
};

/**/
export enum EMentioned {
  COMPANY = 'COMPANY',
  FARM = 'FARM',
  PERSON = 'PERSON',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  VESSEL = 'VESSEL'
}

/**/
export enum EMentionSubject {
  EVENT = 'EVENT',
  NEWS = 'NEWS',
  PRODUCT = 'PRODUCT'
}

/**
 *
 */
export function getNotificationObject(
  type: ENotification
):
  | typeof AdminNotificationObject
  | typeof MentionNotificationObject
  | typeof PromoNotificationObject {
  switch (type) {
    case ENotification.PROMOTION:
      return PromoNotificationObject;

    case ENotification.TAGGED_ENTITY:
      return MentionNotificationObject;

    case ENotification.ADMIN:
    default:
      return AdminNotificationObject;
  }
}
