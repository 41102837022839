import { ENDPOINTS, ENV } from 'other/config';
import {
  EPlayerActions,
  fetchFarmsSet,
  fetchLocationSet,
  fetchTrackSet
} from './playerConstants';
import { http } from 'services/http';

import { TAction } from '../_utils/reducerCreator';
import {
  TFarmArea,
  TFarmLocation,
  TVessel,
  TVesselLocation,
  TVesselTrackPoint
} from 'types';
import { THttpResponse } from 'services/HttpClass';
import { TPlayerState } from './playerModel';
import { TState } from '../appStateModel';

/**/
export const assignEntries = (
  track: TVesselTrackPoint[],
  vessel: TVesselLocation
): TAction<TPlayerState, EPlayerActions> => ({
  type: EPlayerActions.ASSIGN_ENTITIES,
  payload: {
    track: track,
    vessel: vessel
  }
});

/**/
export function fetchData(vesselId: string) {
  return (dispatch, getState) => {
    const { player } = getState() as TState;
    ENV.IS_AF && dispatch(fetchFarmsAction());

    if (player.vessel && player.vessel.id === parseInt(vesselId)) {
      player.track || dispatch(fetchTrack(vesselId));
      return;
    }

    dispatch(fetchTrack(vesselId));
    dispatch(fetchLocationSet.request({ vessel: null }));

    http
      .send(`${ENDPOINTS.VESSELS}/${vesselId}`)
      .then(({ data }: THttpResponse<TVessel>) =>
        dispatch(fetchLocationSet.success({ vessel: data }))
      )
      .catch((e) => dispatch(fetchLocationSet.error(e)));
  };
}

/**/
export function fetchTrack(vesselId: string) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    const url = `${ENDPOINTS.VESSELS}/${vesselId}/path?trackType=${tracks.trackPeriod}`;
    dispatch(fetchTrackSet.request({ track: null }));

    http
      .send(url)
      .then(({ data }: THttpResponse<TVesselTrackPoint[]>) =>
        dispatch(fetchTrackSet.success({ track: data }))
      )
      .catch((e) => dispatch(fetchTrackSet.error(e)));
  };
}

/**/
export function fetchFarmsAction() {
  return (dispatch, getState) => {
    const { farmsLocations, player } = getState() as TState;

    dispatch(
      fetchFarmsSet.request({
        farms: farmsLocations.areas,
        isPending: player.isPending
      })
    );

    Promise.all([
      http.send(ENDPOINTS.FARMS_LOCATION),
      http.send(ENDPOINTS.FARM_AREAS)
    ])
      .then(
        ([cages, farms]: [
          THttpResponse<TFarmLocation[]>,
          THttpResponse<TFarmArea[]>
        ]) => {
          dispatch(
            fetchFarmsSet.success({
              cages: cages.data,
              farms: farms.data['features']
            })
          );
        }
      )
      .catch((e) => dispatch(fetchFarmsSet.error(e)));
  };
}
