import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import './AppButton.scss';

/**/
export type Props = Omit<ButtonProps, 'size'> & {
  mainClassName: string;
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL';
};

/**
 *
 */
export function getBtnCls(
  mainClassName: string,
  className: string,
  size: 'XS' | 'S' | 'M' | 'L' | 'XL'
): string {
  return classnames(
    'AppButton',
    className,
    mainClassName,
    mainClassName + '--' + size || 'M'
  );
}

/**
 *
 */
export function getBtnSize(
  size: 'XS' | 'S' | 'M' | 'L' | 'XL'
): 'small' | 'large' | 'middle' {
  switch (size) {
    case 'XS':
      return 'small';
    case 'XL':
    case 'L':
    case 'M':
      return 'large';
    default:
      return 'middle';
  }
}

/**
 *
 */
export function AppButton(props: Props) {
  const { children, className, mainClassName, size, ...rest } = props;
  return (
    <Button
      {...rest}
      className={getBtnCls(mainClassName, className, size)}
      size={getBtnSize(size)}
    >
      {children}
    </Button>
  );
}
