import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './ActionButton.scss';
import { AppButton } from '../helpers';
import { IconCustom } from '../../IconCustom/IconCustom';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'> & {
  icon: string;
  size?: 'XS' | 'S' | 'M' | 'L';
};

/**
 *
 */
export function ActionButton(props: Props) {
  const { icon, ...rest } = props;
  return (
    <AppButton
      mainClassName="ActionButton"
      shape="circle"
      type="link"
      {...rest}
    >
      <IconCustom className="ActionButton__icon" type={icon} />
    </AppButton>
  );
}
