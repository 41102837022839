import { TEngine } from '../dictionaries';

export type TElectricalPower = {
  primaryGenerators?: (TEngine & {
    quantity?: number;
  })[];
  secondaryGenerators?: (TEngine & {
    quantity?: number;
  })[];
  shaftGenerators?: TEngine & {
    quantity?: number;
  };
  emergencyGenerator?: TEngine;
  batterySystem?: TBatterySystem;
  shoreChargingAvailable?: boolean;
  electricalInstallation?: {
    manufacturerId?: number;
  };
  noAdditionalPowerSourceAvailable: boolean;
};

export enum EElectricalPowerUnit {
  KILOWATTS = 'kW',
  KILOWATTHOURS = 'kWh',
  KILOVOLTAMPERS = 'kVA'
}

export type TBatterySystem = {
  manufacturerId?: number;
  power: number;
  powerUnits: EElectricalPowerUnit;
  capacity: number;
  capacityUnits: EElectricalCapacityUnits;
  quantity: number;
};
export enum EElectricalCapacityUnits {
  KILOWATTS = 'kW',
  KILOWATTHOURS = 'kWh',
  KILOVOLTAMPERS = 'kVA'
}
