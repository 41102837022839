import PropTypes from 'prop-types';

import { PTFarmOfCompany, TFarmOfCompany } from './farm';
import { PTMedia, TMedia, TPhotoSet } from './media';
import {
  PTCreatedBy,
  PTIdLocationDict,
  TCreatedBy,
  TIdLocationDict
} from './general';
import { PTCountryISO, TCountryISO } from './dictionaries';
import {
  PTVessel,
  PTVesselOfCompany,
  PTVesselPhotoSet,
  TVessel,
  TVesselOfCompany
} from './vessel';

export type TEmployee = {
  companyId: number;
  companyRole: TIdLocationDict;
  firstName: string;
  lastName: string;
  personId: number;
  photo: TMedia;
};

export const PTEmployee = {
  companyId: PropTypes.number.isRequired,
  companyRole: PropTypes.exact(PTIdLocationDict),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
  photo: PropTypes.exact(PTMedia)
};

export type TCompany = {
  address?: string;
  boardMembers?: TEmployee[];
  chairman?: TEmployee;
  createdBy: TCreatedBy;
  city?: string;
  country: TCountryISO;
  directors?: TEmployee[];
  employees?: TEmployee[];
  id: number;
  labels?: string;
  name: string;
  postcode?: string;
  registrationNumber?: string;
  status?: TIdLocationDict;
  supportedApps: string[];
  user?: string;
  vessels?: TVessel[];
};

// TODO
export type TCompanyAF = {
  address?: string;
  annualReports?: any; // TODO: correct type object is needed
  boardMembers?: TEmployee[];
  chairman?: TEmployee;
  companies?: TCompanyOfGroup[];
  city?: string;
  country: TCountryISO;
  directors?: TEmployee[];
  fishFarms?: TFarmOfCompany[];
  group?: TCompanyOfGroup;
  id: number;
  isGroup?: boolean;
  labels?: string;
  name: string;
  photos: TPhotoSet[];
  postcode?: string;
  registrationNumber?: string;
  status?: TIdLocationDict;
  supportedApps: string[];
  vessels?: TVesselOfCompany[];
};

export type TCompanyOfGroup = {
  address: string;
  city: string;
  companyId: number;
  country: TCountryISO;
  name: string;
  photos?: TPhotoSet[];
  postcode: string;
  registrationNumber?: string;
};

export const PTCompanyOfGroup = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  country: PropTypes.exact(PTCountryISO),
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  registrationNumber: PropTypes.string
};

export const PTCompany = {
  address: PropTypes.string,
  boardMembers: PropTypes.arrayOf(PropTypes.exact(PTEmployee)),
  chairman: PropTypes.exact(PTEmployee),
  city: PropTypes.string,
  country: PropTypes.exact(PTCountryISO).isRequired,
  createdBy: PropTypes.exact(PTCreatedBy),
  directors: PropTypes.arrayOf(PropTypes.exact(PTEmployee)),
  employees: PropTypes.arrayOf(PropTypes.exact(PTEmployee)),
  id: PropTypes.number.isRequired,
  labels: PropTypes.string,
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string,
  registrationNumber: PropTypes.string,
  status: PropTypes.exact(PTIdLocationDict),
  supportedApps: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.string,
  vessels: PropTypes.arrayOf(PropTypes.exact(PTVessel))
};

export const PTCompanyAF = {
  address: PropTypes.string,
  annualReports: PropTypes.any, // TODO: correct type object is needed
  boardMembers: PropTypes.arrayOf(PropTypes.exact(PTEmployee)),
  chairman: PropTypes.exact(PTEmployee),
  city: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.exact(PTCompanyOfGroup)),
  country: PropTypes.exact(PTCountryISO).isRequired,
  directors: PropTypes.arrayOf(PropTypes.exact(PTEmployee)),
  fishFarms: PropTypes.arrayOf(PropTypes.exact(PTFarmOfCompany)),
  group: PropTypes.exact(PTCompanyOfGroup),
  id: PropTypes.number.isRequired,
  isGroup: PropTypes.bool,
  labels: PropTypes.string,
  name: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.exact(PTVesselPhotoSet)),
  postcode: PropTypes.string,
  registrationNumber: PropTypes.string,
  status: PropTypes.exact(PTIdLocationDict),
  vessels: PropTypes.arrayOf(PropTypes.exact(PTVesselOfCompany))
};

export type TCompanyRegistration = {
  address: string;
  city: string;
  country: string;
  email: string;
  name: string;
  postcode: string;
  telephone: string;
  vatNumber: string;
};

export type TCompanySubscription = {
  description: string;
  id: number;
  paymentTerm: string;
  price: number;
  priceTerm: string;
};

export type TCompaniesFilter = {
  companyValue: number[];
  countryValue: string[];
  fleetValue: number[];
  isFilterSet: boolean;
};

export const PTCompaniesFilter = {
  companyValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  countryValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  fleetValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  isFilterSet: PropTypes.bool.isRequired
};
