import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CarouselCustom } from './CarouselCustom/CarouselCustom';
import { GA } from 'services/analytics';
import { MEDIA_BASE_URL } from 'other/config';
import { shouldRunTracking } from 'services/tracker';

import { EBannerType, TBannerSet, TBannerSlide } from 'types';
import {
  TInjectedWithConnection,
  withConnection
} from 'components/common/withConnection';
import { TState } from 'store/appStateModel';

type Props = OwnProps & {
  campaigns: TBannerSet;
};

type OwnProps = {
  // allows to invoke any kind of method clicking on the item
  onItemClick?: () => void;
  type: EBannerType;
};

type State = {
  autoplay: boolean;
};

class Component extends React.Component<Props, State> {
  static propTypes;
  private elem: HTMLDivElement;

  state: State = { autoplay: false };

  static renderAnchor(
    targetUrl: string,
    imgSrc: string,
    handleClick: () => void
  ): ReactElement {
    const img = <img alt="Banner" src={imgSrc} width="100%" />;
    return targetUrl.startsWith('/') ? (
      <Link key={targetUrl} to={targetUrl} onClick={handleClick}>
        {img}
      </Link>
    ) : (
      <a href={targetUrl}>{img}</a>
    );
  }

  componentDidMount(): void {
    this.reportImpression();
    setTimeout(
      this.startAutoplay,
      CarouselCustom.defaultProps.autoplaySpeed / 2
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { campaigns, type } = this.props;
    if (
      prevProps.campaigns[prevProps.type].length === 0 &&
      campaigns[type].length > 0
    ) {
      this.reportImpression();
    }
  }

  assignRef = (el) => (this.elem = el);

  isMounted(): boolean {
    return !!this.elem;
  }

  isVisible(): boolean {
    const rect = this.elem.getBoundingClientRect();

    return (
      // eslint-disable-next-line react/no-is-mounted
      this.isMounted() &&
      rect.x > 0 &&
      // If less than 20% of the banner is overlapped by the page header.
      // Here 66 equals the header height.
      rect.y > 66 - 0.2 * rect.height
    );
  }

  startAutoplay = (): void =>
    this.isMounted() && this.setState({ autoplay: true });

  handleImpression = (index: number) => {
    const { campaigns, type } = this.props;
    // AntD carousel fires `afterChange` even though there's a sole slide.
    // To prevent reporting an impression we check that there are more than one slide.
    campaigns[type]?.length > 1 && this.reportImpression(index);
  };

  reportImpression(index = 0): void {
    const { campaigns, type } = this.props;

    if (!shouldRunTracking() || !this.isVisible() || !campaigns[type][index]) {
      return;
    }
    GA.reportBannerImpression(campaigns[type][index].campaignId, type);
  }

  mapSlides = ({ campaignId, imagePath, targetPath }: TBannerSlide) => {
    const { onItemClick, type } = this.props;

    const handleClick = () => {
      GA.reportBannerClick(campaignId, type);
      onItemClick instanceof Function && onItemClick();
    };

    return Component.renderAnchor(
      targetPath || '/',
      `${MEDIA_BASE_URL}/${imagePath}`,
      handleClick
    );
  };

  render() {
    const { campaigns, type } = this.props;
    const { autoplay } = this.state;
    if (!campaigns || !campaigns[type]) return;

    return (
      <div key={String(autoplay)} ref={this.assignRef}>
        <CarouselCustom
          afterChange={this.handleImpression}
          autoplay={autoplay}
          buttons={false}
          dots={false}
        >
          {/*@ts-ignore*/}
          {campaigns[type].map(this.mapSlides)}
        </CarouselCustom>
      </div>
    );
  }
}

Component.propTypes = {
  campaigns: PropTypes.object,
  onItemClick: PropTypes.func,
  type: PropTypes.string.isRequired
};

const mapStateToProps = ({ campaign }: TState) => ({
  campaigns: campaign.campaigns
});

/**/
function Carousel({
  isConnectionPoor,
  onItemClick,
  type
}: OwnProps & TInjectedWithConnection) {
  if (isConnectionPoor) return null;

  const Carousel = connect(mapStateToProps)(Component);
  return <Carousel onItemClick={onItemClick} type={type} />;
}

export const BannerCarousel = withConnection(Carousel);
