import PropTypes from 'prop-types';

import {
  ECapacityUnits,
  ECapacityUnits4,
  TFarmAutomation,
  TLoadingUnloading,
  TRefrigeration
} from './common';
import { TElectronics } from './Installations';
import { TManufacturer } from '../dictionaries';

export type TAquaculture = {
  AGDMethod?: EAGD;
  aquaOperations?: TAquaOperations[];
  automation?: TFarmAutomation[];
  cageMaintenance?: TCageMaintenance[];
  // Deprecated. Use `extendedFields.other.fishCapacity
  capacity?: TFarmCapacity;
  delousing?: TDelousing;
  electronics?: TElectronics[];
  handling?: THandlingSystem;
  loadingUnloading?: TLoadingUnloading;
  refrigeration?: TRefrigeration | TRefrigeration[];
  slaughter?: TSlaughter;
  stunBleed: TStunBleed[];
  well?: {
    type: EWellType;
    arrangement: EWellArrangement;
    departmentsAmount: number;
    amount: number;
  };
};

export type TFarmCapacity = {
  value: number;
  units: ECapacityUnits;
};

export enum EWellType {
  WELLS = 'Wells',
  TANKS = 'Tanks',
  CYLINDRICAL_TANKS = 'Cylindrical tanks',
  CIRCULAR_TANKS = 'Circular tanks'
}
export enum EWellArrangement {
  OPEN = 'Open system',
  CLOSED = 'Closed system',
  SEMI = 'Semi closed system',
  OPEN_CLOSED = 'Open/Closed system',
  OPEN_SEMI = 'Open/Semi Closed/Closed system'
}

export type THandlingSystem = {
  type: EHandlingSystem;
  manufacturerId: number;
};
export enum EHandlingSystem {
  FEED = 'Feed Handling System',
  FISH = 'Fish Handling System',
  DEAD_FISH = 'Dead fish'
}

export type TSlaughter = TManufacturer & {
  type: ESlaughter;
  manufacturerId: number;
};

export enum ESlaughter {
  PROCESS = 'Process Station',
  MOBILE_PROCESS = 'Mobile Process Station',
  EMERGENCY_PROCESS = 'Emergency Process Station',
  STUN_AND_BLEED = 'Stun and bleed station'
}

export type TDelousing = {
  method: EDelousing;
  manufacturerId: number;
  quantity: number;
  details: string;
  units: ECapacityUnits4;
  volume: number;
};

export type TStunBleed = {
  details: string;
  manufacturerId: number;
  units: ECapacityUnits4;
  volume: number;
};

export enum EDelousing {
  H2O2 = 'H2O2 Hydrogen Peroxide',
  THERMAL = 'Thermal',
  MECHANICAL = 'Mechanical',
  FRESHWATER = 'Freshwater',
  CLEANING = 'Cleaning Fish',
  LASER = 'Laser',
  FRESHWATER_IN_CAGE = 'Freshwater Delousing In Cage'
}

export enum EAGD {
  H2O2 = 'H2O2 Hydrogen Peroxide',
  FRESHWATER = 'Freshwater'
}

export type TCageMaintenance = {
  manufacturerId: number;
  type: ECageMaintenance;
};

export enum ECageMaintenance {
  ROV = 'ROV',
  RONC = 'RONC',
  HIGH_PRESSURE = 'High Pressure Washers',
  CAGE_CLEANER = 'Cage Cleaner',
  FLYING_NET = 'Flying Net Cleaner',
  NET_CLEANER = 'Net Cleaner',
  CLEANING_RIG = 'Net Cleaning Rig'
}

export type TAquaOperations = {
  manufacturerId: number;
  type: EAquaOperations;
};

export enum EAquaOperations {
  OXYGEN_OZONE = 'Oxygen/Ozone Facility',
  BALLAST_WATER = 'Ballast Water Treatment',
  WATER_UV = 'Water Treatment/UV Filter',
  CO2_AERATOR = 'CO2 Aerator',
  LICE_FILTER = 'Lice Filter',
  SMOLT_CONTROL = 'Smolt Control/Counter',
  FISH_CONTROL = 'Fish Control/Counter',
  SURVEILLANCE_CCTV = 'Surveillance/CCTV',
  TANK_WASH = 'Tank Wash',
  WASH_DISINFECTION = 'Wash and Disinfection',
  OSMOSIS_FACILITY = 'Osmosis facility'
}

export const PTAquaculture = PropTypes.shape({
  capacity: PropTypes.object,
  loadingUnloading: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  well: PropTypes.shape({
    amount: PropTypes.string,
    arrangement: PropTypes.string,
    departmentsAmount: PropTypes.string,
    type: PropTypes.string
  }),
  refrigeration: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  handling: PropTypes.object,
  slaughter: PropTypes.object,
  delousing: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  AGDMethod: PropTypes.string,
  automation: PropTypes.arrayOf(PropTypes.object),
  aquaOperations: PropTypes.arrayOf(PropTypes.object),
  cageMaintenance: PropTypes.arrayOf(PropTypes.object)
});
