import { TFarmAutomation, TLoadingUnloading, TRefrigeration } from './common';

export type TInstallations = {
  automation?: TFarmAutomation[];
  boilers?: {
    manufacturerId: number;
  };
  electronics?: TElectronics[];
  loadingUnloading?: TLoadingUnloading;
  otherInstallations?: TOtherInstallations[];
  processingFactory?: TProcessingFactory | TProcessingFactory[];
  refrigeration?: TRefrigeration;
};

export type TOtherInstallations = {
  manufacturerId: number;
  type: EOtherInstallationsType;
};

export enum EOtherInstallationsType {
  CAPTAINS_CHAIR = 'Captains chair',
  INTERIOR_DESIGN = 'Interior design'
}

export type TElectronics = {
  manufacturerId: number;
  type: EElectronics;
};

export type TProcessingFactory = {
  details?: string;
  manufacturerId?: number;
  type: EProcessingType;
  capacity?: number;
  units: ECapacityUnits3;
};
export enum EProcessingType {
  MEAL_PRODUCTION = 'Fish meal production',
  OIL_PLANT = 'Fish oil plant',
  ENSILAGE_TANKS = 'Ensilage tanks',
  FISH_PROCESSING = 'Fish processing',
  SHRIMP_FACTORY = 'Shrimp Factory',
  HEADING_GUTTING = 'Heading and Gutting',
  COMPLETE_FACTORY = 'Complete factory'
}
export enum ECapacityUnits3 {
  CUBIC_METERS = 'm3',
  TON_DAY = 'ton/day',
  TON = 'Tonnage (ton)'
}

export enum EElectronics {
  FISH_FINDER = 'Fish Finder',
  SONAR = 'Sonar',
  MARINE_RADAR = 'Marine Radar',
  ECDIS = 'ECDIS',
  AIS = 'AIS',
  NAVIGATION_SYSTEM = 'Navigation System',
  MARINE_CAMERAS = 'Marine Cameras',
  COMMUNICATION = 'Communication',
  BRIDGE_CONTROLS = 'Bridge Controls',
  ECHO_SOUNDER = 'Echo Sounder',
  AUTOPILOT = 'Autopilot',
  BRIDGE_SOLUTION = 'Bridge Solution',
  TRAWL_SENSORS = 'Trawl Sensors',
  FISHING_SENSORS = 'Fishing Sensors',
  TRAWL_CAMERA = 'Trawl Camera',
  FISHING_CAMERA = 'Fishing Camera',
  NAVIGATION_PACKAGE = 'Navigation package',
  MACHINE_ROOM_CONTROLS = 'Machine room controls',
  MAINTENANCE_PROGRAM = 'Maintenance program'
}
