import React from 'react';

import './TextButtonUnderlined.scss';
import { AppButton } from '../helpers';
import { ButtonProps } from 'antd/lib/button';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'>;

/**
 *
 */
export function TextButtonUnderlined(props: Props) {
  return (
    <AppButton
      mainClassName="TextButton TextButtonUnderlined"
      type="link"
      {...props}
    />
  );
}
