import PropTypes from 'prop-types';

/**
 * @category Types
 * @subcategory Media
 * @kind Types
 * @group Media
 */
export type TMedia = {
  copyright?: string;
  format: string;
  id: number;
  path: string;
  title: string;
  type: EMediaType | string;
};

export const PTMedia = {
  copyright: PropTypes.string,
  format: PropTypes.string,
  id: PropTypes.number,
  path: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export type TVideo = {
  id: number;
  index: number;
  poster: TMedia;
  serviceProviderId?: number;
  title: string;
  video: TMedia;
};

export type TMediaSource = {
  id: number;
  type: EMediaType;
  name: string;
};

export enum EMediaType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

/**
 * @category Types
 * @subcategory Media
 */
export type TFileDescription = {
  id: number;
  name: string;
  src: string;
};

export type TPhotoSet = {
  id: number;
  position: number;
  original: TMedia;
  big: TMedia;
  thumbnail: TMedia;
};

export type TPhotoItem = {
  description: string;
  fullscreen: string;
  original: string;
  thumbnail: string;
};
