import { TIdName } from './general';

export type TArea = TIdName & {
  area: {
    id: number;
    appearance: TAreaStyle;
    points: TAreaPoint[];
    type?: EAreaType;
  };
  creatorId: number;
  end: string;
  notes: string;
  public: boolean;
  start: string;
};

export type TZone = TIdName & {
  area: {
    id: number;
    name: string;
    points: TAreaPoint[];
    type: EAreaType.PRODUCTION;
  };
  countryId: string; // NO
};

export type TAreaPoint = {
  id: number;
  index: number;
  latitude: number;
  longitude: number;
};

export type TAreaStyle = {
  fillColor: string;
};

export enum EAreaType {
  CUSTOM = 'CUSTOM',
  FISHFARM = 'FISHFARM',
  PRODUCTION = 'PRODUCTION',
  PUBLIC = 'PUBLIC'
}
