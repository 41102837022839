import isequal from 'lodash.isequal';

import { filtersBlankState, filtersInitialState } from './filtersInitialState';
import { SettingsService } from 'services/settings';
import { TFiltersModel } from './filtersModel';

/** Defines whether current settings are different from the initial ones. */
export function isFarmFilterSet(
  settings: TFiltersModel = filtersInitialState
): boolean {
  const { companyValue, countryValue, facilityValue, fleetValue, specieValue } =
    filtersBlankState;

  const blank = {
    companyValue,
    countryValue,
    facilityValue,
    fleetValue,
    specieValue
  };

  const current = {
    companyValue: settings.companyValue,
    countryValue: settings.countryValue,
    facilityValue: settings.facilityValue,
    fleetValue: settings.fleetValue,
    specieValue: settings.specieValue
  };

  return !isequal(blank, current);
}

/** Defines whether current settings are different from the initial ones. */
export function isVesselFilterSet(
  settings: TFiltersModel = filtersInitialState
): boolean {
  const {
    buildYearValue,
    companyValue,
    countryValue,
    engineModelValue,
    enginePowerValue,
    fleetValue,
    lengthValue,
    tonnageValue,
    vesselTypeValue
  } = filtersBlankState;

  const blank = {
    buildYearValue,
    companyValue,
    countryValue,
    engineModelValue,
    enginePowerValue,
    fleetValue,
    lengthValue,
    tonnageValue,
    vesselTypeValue
  };

  const current = {
    buildYearValue: settings.buildYearValue,
    companyValue: settings.companyValue,
    countryValue: settings.countryValue,
    engineModelValue: settings.engineModelValue,
    enginePowerValue: settings.enginePowerValue,
    fleetValue: settings.fleetValue,
    lengthValue: settings.lengthValue,
    tonnageValue: settings.tonnageValue,
    vesselTypeValue: settings.vesselTypeValue
  };

  return !isequal(blank, current);
}

export function storeSettings(settings: TFiltersModel): void {
  SettingsService.writeSettings({ [SettingsService.MAIN_FILTER]: settings });
}
