import PropTypes from 'prop-types';

import { EEntityType, TTargetEntity } from './common';
import { TCrmContact, PTCrmContact } from './contacts';
import { ECurrencyCode } from '../general';
import { PTTargetEntity } from 'types/CRM';
import { TCreatedBy } from 'types';

export type TCurrency = { id: ECurrencyCode; value: any };

export type TAttachedFile = {
  createdAt: string;
  id: number;
  name: string;
  size: number;
};

export enum EProposalStatus {
  ONGOING = 'ONGOING',
  REJECTED = 'REJECTED',
  DEAL = 'DEAL',
  HISTORY = 'HISTORY',
  LEAD = 'LEAD',
  MEETING_BOOKED = 'MEETING_BOOKED',
  MEETING_HELD = 'MEETING_HELD',
  INVOICED = 'INVOICED'
}

export enum EProbability {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum ERecurrence {
  ONCE = 'ONCE',
  EVERY_3_DAYS = 'EVERY_3_DAYS',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type TReminder = {
  id: number;
  recurrence: ERecurrence;
  start: string;
};

export const PTReminder = {
  id: PropTypes.number.isRequired,
  recurrence: PropTypes.oneOf(Object.values(ERecurrence)),
  start: PropTypes.string.isRequired
};

export type TProposal = {
  amount?: number;
  currency?: TCurrency;
  createdBy?: TCreatedBy;
  crmContact?: TCrmContact;
  date: string;
  description?: string;
  files?: TAttachedFile[];
  id: number;
  probability?: EProbability;
  rejectReasonId?: number;
  rejectReasonCustom?: string;
  reminders?: TReminder[];
  status: EProposalStatus;
  targetEntity?: TTargetEntity;
  targetEntityId?: number;
  targetId?: number;
  title: string;
  type?: EEntityType;
};

export const PTProposal = {
  amount: PropTypes.number,
  createdBy: PropTypes.exact({
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    username: PropTypes.string
  }),
  crmContact: PTCrmContact,
  currency: PropTypes.exact({
    id: PropTypes.oneOf(Object.values(ECurrencyCode)),
    value: PropTypes.any
  }),
  date: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.array,
  id: PropTypes.number,
  key: PropTypes.string,
  probability: PropTypes.oneOf(Object.values(EProbability)),
  rejectReasonCustom: PropTypes.string,
  rejectReasonId: PropTypes.number,
  reminders: PropTypes.arrayOf(PropTypes.exact(PTReminder)),
  status: PropTypes.oneOf(Object.values(EProposalStatus)).isRequired,
  targetEntity: PropTypes.exact(PTTargetEntity),
  targetId: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(EEntityType))
};
