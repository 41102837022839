import React from 'react';
import classnames from 'classnames';
import Tag from 'antd/lib/tag';

import './NewTag.scss';

/**/
type Props = {
  className?: string;
  text?: string;
};

/**
 *
 */
function NewTag({ className, text }: Props) {
  const cls = classnames('NewTag', className);
  return <Tag className={cls}>{text}</Tag>;
}

/**/
NewTag.defaultProps = {
  text: 'New'
};
export { NewTag };
