import createAsyncActions from 'store/_utils/createAsyncActions';
import { TProvidersState } from './providersModel';

export enum EProvidersActions {
  FETCH_PROVIDERS = 'providers/FETCH_PROVIDERS',
  FETCH_BRANCHES = 'providers/FETCH_BRANCHES',
  SET_FILTER = 'providers/SET_FILTER',
  RESET_FILTER = 'providers/RESET_FILTER',
  PROVIDERS_FILTER_PARAM_CATEGORY = 'providers/PROVIDERS_FILTER_PARAM_CATEGORY',

  SET_CATEGORIES_LIST = 'providers/SET_CATEGORIES_LIST',
  SET_CATEGORIES_MAP = 'providers/SET_CATEGORIES_MAP',
  SET_COUNTRIES_LIST = 'providers/SET_COUNTRIES_LIST'
}

export const fetchBranchesSet = createAsyncActions<TProvidersState>(
  EProvidersActions.FETCH_BRANCHES
);
export const fetchProvidersSet = createAsyncActions<TProvidersState>(
  EProvidersActions.FETCH_PROVIDERS
);
