import { ENDPOINTS } from 'other/config';

import { TFleet, TFleetItems } from 'types';
import { TFleetsState } from './fleetsModel';
import { THttpRequestOptions } from 'services/HttpClass';

enum EFleetKey {
  COMPANIES = 'companyIds',
  FARMS = 'fishFarmIds',
  VESSELS = 'vesselIds'
}
enum ESuffix {
  COMPANIES = 'companies',
  FARMS = 'fishfarms',
  VESSELS = 'vessels'
}

enum ECandidateKey {
  COMPANIES = 'companies',
  FARMS = 'farms',
  VESSELS = 'vessels'
}

/**
 * Handles 'add vessel(s) to fleet', 'remove vessel(s) from fleet`, 'rename fleet' operations.
 */
export class FleetUpdateUtil {
  private readonly fleet: TFleet; // the operated fleet
  private readonly fleets: TFleet[]; // all the fleets
  private readonly candidates: TFleetItems;
  private readonly suffix: ESuffix;
  private readonly candidateKey: ECandidateKey;
  private readonly fleetKey: EFleetKey;

  constructor(
    fleet: TFleet,
    fleets: TFleet[],
    candidates: TFleetItems = {} as any
  ) {
    this.fleet = fleet;
    this.fleets = fleets;
    this.candidates = candidates;

    if (candidates.companies?.length > 0) {
      this.candidateKey = ECandidateKey.COMPANIES;
      this.suffix = ESuffix.COMPANIES;
      this.fleetKey = EFleetKey.COMPANIES;
    } else if (candidates.farms?.length > 0) {
      this.candidateKey = ECandidateKey.FARMS;
      this.suffix = ESuffix.FARMS;
      this.fleetKey = EFleetKey.FARMS;
    } else if (candidates.vessels?.length > 0) {
      this.candidateKey = ECandidateKey.VESSELS;
      this.suffix = ESuffix.VESSELS;
      this.fleetKey = EFleetKey.VESSELS;
    } else if (Object.keys(candidates).length > 0) {
      throw new TypeError(
        `FLEETS: wrong item type: ${JSON.stringify(candidates)}`
      );
    }
  }

  /// 'ADD VESSEL TO FLEET' OPERATION SECTION ///
  getAddOperationRequestParams(): THttpRequestOptions {
    return this.getRequestParams('PUT');
  }

  /** Returns the fleet model partial update for 'add vessel to fleet' case. */
  getAddOperationPayload(): Partial<TFleetsState> {
    const update = this.fleet[this.fleetKey].concat(
      this.candidates[this.candidateKey]
    );
    return {
      fleets: this.updateFleets({ [this.fleetKey]: update })
    };
  }

  /// 'REMOVE VESSEL FROM FLEET' OPERATION SECTION ///
  getRemoveOperationRequestParams(): THttpRequestOptions {
    return this.getRequestParams('DELETE');
  }

  /** Returns the fleet model partial update for 'remove vessel from fleet' case. */
  getRemoveOperationPayload(): Partial<TFleetsState> {
    const update = this.fleet[this.fleetKey].filter(
      (id: number): boolean => !this.candidates[this.candidateKey].includes(id)
    );
    return {
      fleets: this.updateFleets({ [this.fleetKey]: update })
    };
  }

  /// REMOVE SECTION ///
  getRenameOperationRequestParams(name: string): THttpRequestOptions {
    const url = `${ENDPOINTS.FLEET}/${this.fleet.id}`;

    return {
      body: { name: name },
      method: 'PUT',
      url: url
    };
  }

  /** Returns the fleet model partial update for 'remove vessel from fleet' case. */
  getRenameOperationPayload(name: string): Partial<TFleetsState> {
    return {
      fleets: this.updateFleets({ name: name })
    };
  }

  /// GENERAL SECTION
  getRequestParams(method: 'PUT' | 'DELETE'): THttpRequestOptions {
    return {
      body: {
        [this.fleetKey]: this.candidates[this.candidateKey]
      },
      method: method,
      url: `${ENDPOINTS.FLEET}/${this.fleet.id}/${this.suffix}`
    };
  }

  /** Updates a particular fleet in the list. */
  private updateFleets(update: Partial<TFleet>): TFleet[] {
    const fleet = {
      ...this.fleet,
      ...update
    };
    return (this.fleets || []).map(
      (f: TFleet): TFleet => (f.id === this.fleet.id ? fleet : f)
    );
  }
}
