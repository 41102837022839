import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spin from 'antd/lib/spin';
import './Loading.scss';

type Props = {
  /** a CSS class name. */
  className?: string;
};

/**
 * An animated spinner.
 * Placed into a <code>position: relative</code> container.
 * @param className
 * @constructor
 */
const Loading: React.FC<Props> = ({ className }): ReactElement => {
  const cssClass = classnames('Loading', className);
  return (
    <div className={cssClass}>
      <Spin size="large" />
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

export { Loading };
