import React from 'react';

import { shouldRunTracking } from 'services/tracker';
import TrackerCore from './TrackerCore';
import {
  TInjectedWithConnection,
  withConnection
} from 'components/common/withConnection';

/**/
function Tracker(props: TInjectedWithConnection) {
  return shouldRunTracking() ? <TrackerCore {...props} /> : null;
}

/**/
export default withConnection(Tracker);
