import { filtersBlankState } from './filtersInitialState';
import { isFarmFilterSet, isVesselFilterSet, storeSettings } from './helpers';
import { isMap } from 'components/helpers/helpers';
import { watchLocationsAction } from 'store/map/actionMediator';

import { EFiltersActions } from './filtersConstants';
import { TAction } from '../_utils/reducerCreator';
import { TFiltersModel } from './filtersModel';
import { TState } from '../appStateModel';

export function setFiltersAction(value: Partial<TFiltersModel>) {
  return (dispatch, getState) => {
    const { filters } = getState() as TState;
    // It's called 'partial' because we still need to update 'isFilterSet'.
    const partialUpdate: TFiltersModel = {
      ...filters,
      ...value
    };
    const update: TFiltersModel = {
      ...partialUpdate,
      isFarmFilterSet: isFarmFilterSet(partialUpdate),
      isVesselFilterSet: isVesselFilterSet(partialUpdate)
    };

    dispatch({
      payload: update,
      type: EFiltersActions.SET_FILTER
    });

    if (isMap()) {
      dispatch(watchLocationsAction());
    }

    storeSettings(update);
  };
}

/**
 * Resets the filter to the defaults.
 */
export const resetFiltersAction = (): TAction<
  TFiltersModel,
  EFiltersActions
> => {
  storeSettings(filtersBlankState);

  return {
    payload: filtersBlankState,
    type: EFiltersActions.RESET_FILTER
  };
};

/**
 * Created for the purpose of updating `isFilterSet` flag.
 */
export const refreshFiltersAction = (): TAction<
  TFiltersModel,
  EFiltersActions
> => ({
  payload: {
    isFarmFilterSet: isFarmFilterSet(),
    isVesselFilterSet: isVesselFilterSet()
  },
  type: EFiltersActions.REFRESH_FILTER
});
