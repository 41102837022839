import { TAction } from './reducerCreator';

type Actions<S> = {
  request: (data?: Partial<S>) => TAction<S, string>;
  success: (data?: Partial<S>) => TAction<S, string>;
  error: (e: any, data?: Partial<S>) => TAction<S, string>;
};

/**
 * Creates a set of request-success-error async action creators by given action
 * group name.
 * Each of the creators accepts optional data or error-data parameters.
 * S - domain state slice (helps checking payload type).
 */
function createAsyncActions<S>(actionGroupName: string): Actions<S> {
  if (typeof actionGroupName !== 'string') {
    throw new TypeError(
      `"createAsyncActions": bad actionGroupName "${actionGroupName}"`
    );
  }

  const request = (data: Partial<S> = {}): TAction<S, string> => ({
    type: actionGroupName + createAsyncActions.STARTED,
    payload: {
      isPending: true,
      error: null,
      ...data
    }
  });

  const success = (data: Partial<S> = {}): TAction<S, string> => ({
    type: actionGroupName + createAsyncActions.DONE,
    payload: {
      isPending: false,
      error: null,
      ...data
    }
  });

  const error = (error: Error, data: Partial<S> = {}): TAction<S, string> => {
    const type = actionGroupName + createAsyncActions.FAILED;
    window.console.error(`${type}\n${error}`);
    window.console.error(error.stack);

    return {
      type: type,
      payload: {
        isPending: false,
        error: error,
        ...data
      }
    };
  };

  return {
    request: request,
    success: success,
    error: error
  };
}

/**/
createAsyncActions.DONE = '_DONE';
createAsyncActions.FAILED = '_FAILED';
createAsyncActions.STARTED = '_STARTED';

export default createAsyncActions;

/**/
/**/
/**/
// DO NOT REMOVE!
// CURRENTLY UNUSED. KEPT HERE FOR THE FUTURE.
class AsyncActions<S> {
  private static readonly DONE = '_DONE';
  private static readonly FAILED = '_FAILED';
  private static readonly STARTED = '_STARTED';
  private readonly onError: (msg: string) => void;

  constructor(onError) {
    this.onError = onError;
    // @ts-ignore
    return this.createAsyncActions;
  }

  private createAsyncActions<S>(actionGroupName: string): Actions<S> {
    if (typeof actionGroupName !== 'string') {
      throw new TypeError(
        `"createAsyncActions": bad actionGroupName "${actionGroupName}"`
      );
    }

    const request = (data: Partial<S> = {}): TAction<S, string> => ({
      type: actionGroupName + AsyncActions.STARTED,
      payload: {
        isPending: true,
        error: null,
        ...data
      }
    });

    const success = (data: Partial<S> = {}): TAction<S, string> => ({
      type: actionGroupName + AsyncActions.DONE,
      payload: {
        isPending: false,
        error: null,
        ...data
      }
    });

    const error = (error: Error, data: Partial<S> = {}): TAction<S, string> => {
      const type = actionGroupName + AsyncActions.FAILED;
      window.console.error(`${type}\n${error}`);
      window.console.error(error.stack);

      return {
        type: type,
        payload: {
          isPending: false,
          error: error,
          ...data
        }
      };
    };

    return {
      request: request,
      success: success,
      error: error
    };
  }
}

// const createAsyncActions = new AsyncActions(message.error);
// export default createAsyncActions as any as <S>(name: string) => Actions<S>;
