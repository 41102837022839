import { configureScope } from '@sentry/browser';
import message from 'antd/lib/message';
import { push } from 'connected-react-router';

import { API_URL, ENDPOINTS, FF_CONSENT } from 'other/config';
import {
  clearAuthSettings,
  getCredentialsIfPreserved,
  getToken,
  saveCredentials,
  saveToken,
  setKeepLogged
} from 'services/auth';
import {
  followRedirectPathAction,
  storeSessionAction
} from 'store/session/sessionActions';
import { getThemeState, setTheme } from '../misc/miscActions';
import { http } from 'services/http';
import { isTestRunner } from 'other/helpers';
import { loginSet } from './loginConstants';
import { prefetchDataAction } from 'store/dictionaries/dictionariesActions';
import { resetStore } from 'store/root/rootActions';
import { ROUTES } from 'other/constants';
import { runPushService } from 'services/pushNotification/pushNotificationService';

import { TCredentials, TUser } from 'types';
import { THttpResponse } from 'services/HttpClass';
import { TState } from '../appStateModel';

/**
 * Forms login request body and hits the login endpoint.
 */
export function sendLoginRequest(
  username: string,
  password: string,
  skipToken?: boolean
): Promise<any> {
  const params = new FormData();
  params.append('username', username);
  params.append('password', password);

  return http.send(
    {
      body: params,
      credentials: 'include',
      method: 'POST',
      url: ENDPOINTS.LOGIN
    },
    {
      skipToken: skipToken
    }
  );
}

/**
 * Login response handler. Handles 401, general errors and success.
 */
export const handleLoginResponse = (
  res: THttpResponse<TUser>,
  dispatch
): void | never => {
  if (res.status === 401) {
    clearAuthSettings();
    dispatch(push(ROUTES.LOGIN));
    throw new Error('Bad credentials or token expired!');
  }

  if (!res || !res.ok) {
    const msg = 'Network problem. Please, retry later.';
    throw new Error(res.statusText || msg);
  }

  saveToken(res.data.token);
  document.cookie = `${FF_CONSENT}=true`;
  document.getElementById('rcc-confirm-button')?.click();

  configureScope((scope) =>
    scope.setUser({
      id: String(res.data.userInfo.id)
    })
  );

  dispatch(loginSet.success({ displayError: null }));
  dispatch(storeSessionAction(res.data));
  dispatch(followRedirectPathAction());
  dispatch(prefetchDataAction(true));
  runPushService(res.data.token);
};

/**
 * Fired by a login form.
 * @param username
 * @param password
 * @param shouldKeepLogged
 */
export function loginAction(
  username: string,
  password: string,
  shouldKeepLogged: boolean
) {
  return (dispatch) => {
    setKeepLogged(shouldKeepLogged);
    shouldKeepLogged && saveCredentials({ password, username } as TCredentials);

    dispatch(loginSet.request());

    sendLoginRequest(username, password, true)
      .then((res: THttpResponse<TUser>) => handleLoginResponse(res, dispatch))
      .catch((e: Error) => {
        if (isTestRunner()) return;

        const message = e.message.includes('401')
          ? 'Wrong user name and/or password'
          : e.message;

        dispatch(
          loginSet.error(e, {
            displayError: message
          })
        );
      });
  };
}

export function autoLoginAction() {
  return async (dispatch, getState) => {
    const { login } = getState() as TState;
    if (login.isPending) return;

    const credentials = getCredentialsIfPreserved() as TCredentials;

    if (!credentials) {
      saveToken();
    }

    dispatch(loginSet.request());

    try {
      const res = await sendLoginRequest(
        credentials?.username,
        credentials?.password
      );

      handleLoginResponse(res, dispatch);
    } catch (e) {
      dispatch(loginSet.error(e));
    }
  };
}

/**
 * Hits logout endpoint, reloads the page.
 */
export async function sendLogoutRequest(): Promise<void> {
  try {
    await fetch(`${API_URL}${ENDPOINTS.LOGOUT}`, {
      credentials: 'include',
      headers: { 'X-Auth-Token': getToken() },
      method: 'DELETE'
    });
  } catch ({ message: errorMessage }) {
    message.error(errorMessage);
  }
}

/**
 * Fired on logOut. Clears the auth token and user data.
 */
export function logoutAction() {
  return (dispatch) => {
    const { isDark } = getThemeState();

    sendLogoutRequest();
    dispatch(resetStore);
    document.cookie = '';

    window.localStorage.clear();
    window.sessionStorage.clear();

    // set theme only after logout
    dispatch(setTheme(isDark));
  };
}
