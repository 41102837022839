import dayjs from 'dayjs';

import { DEPTH_AVAILABILITY_LAYERS } from 'other/map/layerValues';
import { getElevation } from 'other/map/layerValueHelpers';
import { isTouchDevice } from 'other/helpers';

import {
  EMapLayer,
  EMarkerCategory,
  EMiniCardPanel,
  TDraggableMapEntity,
  TFarmArea,
  TFarmLocation,
  TFarmLocationExt,
  TIdName,
  TLayerPointLocation,
  TLayerPointValue,
  TMapEntityState,
  TLayerValues,
  TVesselLocation,
  TVesselLocationExt
} from 'types';
import { TMapEntities } from './mapEntitiesModel';

/**
 *
 */
export function doEntitiesDedupe<T extends TIdName>(entities: T[]): T[] {
  if (!Array.isArray(entities) || entities.length === 0) return entities;

  const map = new Map();
  entities.forEach((ent) => ent && map.set(ent.id, ent));

  return Array.from(map.values());
}

/**
 *
 */
export function dedupeEntities(entities: {
  [EMarkerCategory.CAGE]: TFarmLocation[] | TFarmLocationExt[];
  [EMarkerCategory.VESSEL]: TVesselLocation[] | TVesselLocationExt[];
}): {
  [EMarkerCategory.CAGE]: TFarmLocation[] | TFarmLocationExt[];
  [EMarkerCategory.VESSEL]: TVesselLocation[] | TVesselLocationExt[];
} {
  return {
    [EMarkerCategory.CAGE]: doEntitiesDedupe(entities[EMarkerCategory.CAGE]),
    [EMarkerCategory.VESSEL]: doEntitiesDedupe(entities[EMarkerCategory.VESSEL])
  };
}

/**
 *
 */
export const DEFAULT_MINI_CARD_PANEL = isTouchDevice()
  ? EMiniCardPanel.RIGHT
  : EMiniCardPanel.LEFT;

/**/
export type SimpleEntity = {
  id?: number;
  providerId?: number;
};

/**/
export function makeFarmFromArea(
  areas: TFarmArea[],
  farmId: number
): TFarmLocation {
  const area = areas.find(
    (a: TFarmArea) => a.properties.fishfarm.id === farmId
  );
  if (!area) return null;

  return {
    ...area?.properties.fishfarm,
    latitude: area.geometry.coordinates[0][0][1],
    longitude: area.geometry.coordinates[0][0][0]
  } as any;
}

/**/
export function categoryContainsItem(
  entities: TMapEntities,
  targetItem: SimpleEntity,
  type: EMarkerCategory
): boolean {
  const items = entities[type] as [any];
  const item = items.find(
    (e) =>
      (e.id && targetItem.id && e.id === targetItem.id) ||
      (e.providerId &&
        targetItem.providerId &&
        e.providerId === targetItem.providerId)
  );
  return !!item;
}

/**
 * Layers and layer specific data section.
 */

/**
 *
 */
const LAYERS = {
  [EMapLayer.PHYTOPLANKTON]: 'cns:PlanktonData',
  [EMapLayer.SALINITY]: 'cns:SalinityData',
  [EMapLayer.SEATEMP]: 'cns:SeaTemperatureData',
  [EMapLayer.SURFACETEMP]: 'cns:SeaTemperatureData',
  [EMapLayer.ZOOPLANKTON]: 'cns:ZooplanktonData'
};

/**
 *
 */
export function fetchLocationValue(
  { coords, ij, latitude, longitude }: TLayerPointLocation,
  layer: EMapLayer,
  layerValuesMap: Record<EMapLayer, TLayerValues>,
  date
): Promise<TLayerPointValue> {
  const { depth, range } = layerValuesMap[layer];

  let url =
    'https://maps.fishfacts.com/geoserver/gwc/service/wmts' +
    '?VERSION=1.0.0' +
    `&LAYER=${LAYERS[layer]}` +
    '&STYLE=' +
    '&TILEMATRIX=EPSG:3857:' +
    coords.z +
    '&TILEMATRIXSET=EPSG:3857&SERVICE=WMTS&FORMAT=image/png&SERVICE=WMTS' +
    '&REQUEST=GetFeatureInfo&INFOFORMAT=application/json' +
    `&TileCol=${coords.x}&TileRow=${coords.y}` +
    `&I=${ij.i}&J=${ij.j}` +
    `&TIME=${dayjs(date).format('YYYY-MM-DD')}`;

  if (DEPTH_AVAILABILITY_LAYERS.includes(layer)) {
    url += `&ELEVATION=${getElevation(depth, layer)}`;
  }

  return fetch(url)
    .then((r) => r.json())
    .then(({ features, timeStamp }): TLayerPointValue => {
      const value = Object.values(features[0]?.properties)[0] as number;
      if (!isValueValid(value, range)) return;

      return {
        latitude: latitude,
        longitude: longitude,
        timeStamp: timeStamp,
        title: getTitle(layer),
        units: getUnits(layer),
        value: value
      };
    });
}

/**
 *
 */
function getTitle(layer: EMapLayer): string {
  switch (layer) {
    case EMapLayer.PHYTOPLANKTON:
      return 'Phytoplankton';
    case EMapLayer.SALINITY:
      return 'Salinity';
    case EMapLayer.SEATEMP:
      return 'Sub sea temperature';
    case EMapLayer.SURFACETEMP:
      return 'Surface temperature';
    case EMapLayer.ZOOPLANKTON:
      return 'Zooplankton';
  }
}

/**
 *
 */
function getUnits(layer: EMapLayer): string {
  switch (layer) {
    case EMapLayer.ZOOPLANKTON:
    case EMapLayer.PHYTOPLANKTON:
      return 'mmol/m&sup3;';
    case EMapLayer.SALINITY:
      return 'g/kg';
    case EMapLayer.SEATEMP:
    case EMapLayer.SURFACETEMP:
      return '°C';
  }
}

/**/
function isValueValid(value: unknown, range: [number, number]): boolean {
  return typeof value === 'number' && value >= range[0] && value <= range[1];
}

/**
 *
 */
export function updateEntityUIState(
  entityId: number,
  list: TDraggableMapEntity[],
  uiState: Partial<TMapEntityState>
): TDraggableMapEntity[] {
  return list.map((item: TDraggableMapEntity) => {
    if (item.id !== entityId) return item;
    const stateUpdate = {
      ...(item.uiState || null),
      ...uiState
    };
    return {
      ...item,
      uiState: stateUpdate
    };
  });
}

/* Tracks coloring */
export const colorArray: string[] = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF'
];
