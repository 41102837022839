import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { assignCampaignsAction } from '../campaign/campaignActions';
import { buildQueryString } from '../_utils/helpers';
import { http } from 'services/http';
import { ENDPOINTS, SUB_POINTS } from 'other/config';
import { errorAction, okAction } from '../_utils/syncActions';
import { pluckFromFields } from 'other/formAndValidation/formUtils';
import { PROMO_SEARCH_VALUE, ROUTES } from 'other/constants';
import { promoInitialState } from './promoInitialState';
import { selectVesselTypesOptions } from 'components/common/filters/VesselTypesFilter/selectVesselTypesOptions';
import { shuffleArray } from 'other/helpers';

import {
  EPromoActions,
  fetchReportSet,
  fetchSet,
  submitSet
} from './promoConstants';

import {
  EBannerType,
  EPromotedType,
  TBannerSet,
  TFormFields,
  TPaymentReport,
  TPromoted,
  TPromotion,
  TPromotionCost,
  TSelectOption
} from 'types';
import { TAction } from '../_utils/reducerCreator';
import { THttpResponse } from 'services/HttpClass';
import { TPromoState } from './promoModel';
import { TState } from '../appStateModel';

dayjs.extend(utc);

export type TPromoOptions = {
  countries: TSelectOption<string>[];
  services: TSelectOption<number>[];
  vesselTypes: TSelectOption<number>[];
};

/**
 * Fetch promoted articles
 */
export function fetchPromotedAction() {
  return (dispatch, getState) => {
    const {
      promo: { isPending, articles }
    } = getState() as TState;

    if (isPending || articles.length > 0) return;

    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.PROMOTION)
      .then(({ data }: THttpResponse<TPromoted[]>) => {
        const { banners, news } = sortPromotions(data);
        dispatch(fetchSet.success({ articles: news }));
        dispatch(assignCampaignsAction(banners));
      })
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}

export function calculateCostAction(fields: TFormFields, articleId: number) {
  return (dispatch, getState) => {
    const {
      dictionaries: { promotionCountries, vesselTypes },
      promo,
      providers: { categories }
    } = getState() as TState;

    if (promo.isCalculating) return;

    const options = {
      countries: promotionCountries,
      services: categories,
      vesselTypes: selectVesselTypesOptions(vesselTypes)
    } as any;

    const body = createPromoBody(fields, articleId, options);
    const url = `${ENDPOINTS.PROMOTION_COST}?${buildQueryString(body)}`;

    dispatch(updateFieldsAction(fields));
    dispatch(
      okAction(EPromoActions.CALCULATE_COST_REQUEST, { isCalculating: true })
    );

    http
      .send(url)
      .then(({ data }: THttpResponse<TPromotionCost>) =>
        dispatch(
          okAction(EPromoActions.CALCULATE_COST_SUCCESS, {
            cost: data,
            isCalculating: false
          })
        )
      )
      .catch((e: Error) =>
        dispatch(
          errorAction(EPromoActions.CALCULATE_COST_FAIL, e, {
            isCalculating: false
          })
        )
      );
  };
}

export function submitPromotionAction(articleId: number) {
  return (dispatch, getState) => {
    const {
      dictionaries: { promotionCountries, vesselTypes },
      promo: { fields },
      providers: { categories }
    } = getState() as TState;

    const options = {
      countries: promotionCountries,
      services: categories,
      vesselTypes: selectVesselTypesOptions(vesselTypes)
    } as any;

    dispatch(submitSet.request());

    http
      .send({
        body: createPromoBody(fields, articleId, options),
        method: 'POST',
        url: ENDPOINTS.PROMOTION
      })
      .then(({ data }: THttpResponse<TPromoted>) => {
        dispatch(submitSet.success());
        postPayment(data);
        dispatch(clearPromotionAction());
      })
      .catch((e) => dispatch(submitSet.error(e)));
  };
}

export function fetchPaymentReportAction(promoId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.PROMOTION}/${promoId}${SUB_POINTS.PAYMENTS}`;
    dispatch(fetchReportSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TPaymentReport[]>) =>
        dispatch(
          fetchReportSet.success({
            report: data[0] || null
          })
        )
      )
      .catch((e) => dispatch(fetchReportSet.error(e)));
  };
}

export function postPayment(promotion: TPromoted): void {
  const url = `${ENDPOINTS.PROMOTION}/${promotion.id}${SUB_POINTS.PAYMENTS}`;
  const body = {
    redirectUrl: `${window.location.origin}${ROUTES.NEWS_EDITOR}/${promotion.news.id}?q=${PROMO_SEARCH_VALUE}`
  };

  http
    .send({
      body: body,
      method: 'POST',
      url: url
    })
    .then(({ data }: THttpResponse<TPaymentReport>) => {
      window.location.href = decodeURIComponent(data.checkoutLink);
    })
    .catch(window.console.error);
}

export const clearPromotionAction = (): TAction<
  TPromoState,
  EPromoActions
> => ({
  type: EPromoActions.RESET,
  payload: promoInitialState
});

export const updateFieldsAction = (
  promo: TFormFields
): TAction<TPromoState, EPromoActions> => ({
  type: EPromoActions.UPDATE_PROMOTION,
  payload: { fields: promo }
});

/**
 * Separates news and banners promotions. Sorts the banners into the categories.
 */
export function sortPromotions(arr: TPromoted[]): {
  news: TPromoted[];
  banners: TBannerSet;
} {
  const banners = {
    [EBannerType.MENU]: [],
    [EBannerType.NEWS]: [],
    [EBannerType.FILTERS]: [],
    [EBannerType.MOBILE]: []
  };
  const news = [];

  shuffleArray(arr).forEach((item: TPromoted) => {
    if (item.type === EPromotedType.NEWS) {
      news.push(item);
    } else {
      const {
        bannerUrl,
        filtersBanner,
        id,
        menuBanner,
        mobileBanner,
        newsBanner
      } = item;

      if (
        !bannerUrl ||
        !filtersBanner ||
        !menuBanner ||
        !mobileBanner ||
        !newsBanner
      )
        return;

      const obj = {
        campaignId: id,
        targetPath: bannerUrl
      };

      banners[EBannerType.FILTERS].push({
        imagePath: filtersBanner ? filtersBanner.path : null,
        ...obj
      });
      banners[EBannerType.MENU].push({
        imagePath: menuBanner ? menuBanner.path : null,
        ...obj
      });
      banners[EBannerType.MOBILE].push({
        imagePath: mobileBanner ? mobileBanner.path : null,
        ...obj
      });
      banners[EBannerType.NEWS].push({
        imagePath: newsBanner ? newsBanner.path : null,
        ...obj
      });
    }
  });

  return { banners, news };
}

function getOptions(
  chosenIds: number[],
  options: TSelectOption<number>[]
): number[] {
  if (!chosenIds) return null;

  return chosenIds.length === 0 ? options.map((o) => o.value) : chosenIds;
}

export function createPromoBodyCore(
  fields: TFormFields,
  options: TPromoOptions
): Partial<TPromotion> {
  const values = pluckFromFields(fields);
  const countries =
    values.countries.length === 0
      ? options.countries.map((c: TSelectOption<string>): string => c.value)
      : values.countries;

  const services = getOptions(values.serviceCategories, options.services);
  const types = getOptions(values.vesselTypes, options.vesselTypes);

  return {
    ...values,
    countries: countries.sort(),
    end: values.end
      ? values.end.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
      : void 0,
    start: values.start
      ? values.start.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
      : void 0,
    ...(services && { serviceCategories: services }),
    ...(values.showNewsAfterEnd && {
      showNewsAfterEnd: values.showNewsAfterEnd
    }),
    ...(types && { vesselTypes: types })
  };
}

export function createPromoBody(
  fields: TFormFields,
  articleId: number,
  options: TPromoOptions
): Partial<TPromotion> {
  return {
    newsId: articleId,
    type: EPromotedType.NEWS,
    ...createPromoBodyCore(fields, options)
  };
}
