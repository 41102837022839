export const ffVariables = {
  token: {
    colorPrimary: '#4e74fc',
    colorPrimaryActive: '#3855d6',
    colorPrimaryBorder: 'rgba(78, 116, 252, 0.2)',
    colorPrimaryHover: '#789aff',
    colorLink: '#4e74fc',
    colorLinkActive: '#3855d6',
    colorLinkHover: '#789aff'
  },
  components: {
    Button: {
      // colorLink: '#4e74fc',
      // colorLinkHover: '#789aff',
      // colorPrimaryHover: '#355DEC'
    },
    Slider: {
      colorBgElevated: '#4e74fc',
      colorPrimaryBorder: '#4e74fc',
      colorPrimaryBorderHover: '#789aff'
    }
  }
};
