export const darkCommonVariables = {
  token: {
    colorBgContainer: '#2e344e',
    colorBgBase: '#2e344e',
    colorText: '#D0D0D0',
    colorWhite: '#2e344e'
  },
  components: {
    Button: {
      colorBorder: '#4e5b6a'
    },
    Checkbox: {
      colorBgContainer: '#2e344e',
      colorBorder: '#4e5b6a',
      colorWhite: 'white'
    },
    Collapse: {
      colorTextHeading: '#f5f5f5',
      collapseContentBg: '#23273a' // doesn't take effect
    },
    ColorPicker: {
      colorBorder: '#4e5b6a' // --button-default-border
    },
    DatePicker: {
      colorBorder: '#4e5b6a'
    },
    Drawer: {
      colorBgElevated: '#23273a',
      colorTextDisabled: '#D0D0D0'
    },
    // FormItem: {
    // marginBottom: '24px;'
    // },
    Form: {
      colorTextHeading: '#f5f5f5'
    },
    Input: {
      colorBgContainer: '#23273a', // (--body-background)
      colorBorder: '#4e5b6a', // --button-default-border
      colorText: '#D0D0D0'

      // inputBorderActiveColor: '#ff8b6b', // (--primary-color-light)
      // inputBorderHoverColor: '#ff8b6b', // (--primary-color-light)
    },
    InputNumber: {
      colorBgContainer: '#23273a', // (--body-background)
      colorBorder: '#4e5b6a'
    },
    Modal: {
      colorTextHeading: '#f5f5f5', // doesn't take effect
      modalContentBg: '#23273a' // doesn't take effect
    },
    Radio: {
      colorBorder: '#4e5b6a'
    },
    Select: {
      colorBgContainer: 'transparent',
      colorBorder: '#4e5b6a',
      colorText: '#D0D0D0',
      controlItemBgActive: '#4e5b6a'
    },
    Slider: {
      colorText: '#D0D0D0'
    },
    Switch: {
      colorTextQuaternary: '#575B70'
    },
    // Table: {
    //   tableFooterBg: '#23273a',
    //   tableHeaderBg: '',
    //   tableHeaderTextColor: '',
    //   tablePaddingVertical: '',
    // tablePaddingHorizontal: '',
    // },
    Tabs: {
      colorSplit: '#4e5b6a',
      tabsCardHeadBackground: '#23273a !important' // doesn't take effect
    },
    Tag: {
      tagDefaultColor: '#D0D0D0'
    },
    Tooltip: {}
  }
};
