export type TFileUploaderParams = {
  chunkSize?: number;
  onError: (error: Error) => void;
  onProgress: (progress: number) => void;
  onSuccess: (responseText: string) => void;
  token: () => string | string;
};

/**
 * File upload util. Able to send a file in chunks.
 */
export class Uploader {
  /**
   * Returns progress percent.
   */
  static getProgress(loaded: number, total: number): number {
    return Math.floor((100 * loaded) / total) - 1;
  }

  private readonly chunkSize: number;
  private file: File;
  private url: string;

  private readonly onError: (error: Error) => void;
  private readonly onProgress: (progress: number) => void;
  private readonly onSuccess: (responseText: string) => void;
  private readonly token: () => string | string;
  private progressArray = [];

  /** To use chunk upload specify `chunkSize` value in bytes. */
  constructor(options: TFileUploaderParams) {
    const { chunkSize, onError, onProgress, onSuccess, token } = options;
    this.chunkSize = chunkSize;
    this.onProgress = onProgress;
    this.onError = onError;
    this.onSuccess = onSuccess;
    this.token = token;
  }

  /**
   * Fires uploading. Give it the three callbacks.
   */
  upload(file: File, url: string) {
    this.file = file;
    this.url = url;
    this.chunkSize ? this._handleChunks() : this._postFile();
  }

  /**
   * Creates a request and does common setup.
   */
  private _createRequest(): XMLHttpRequest {
    const _token = typeof this.token === 'function' ? this.token() : this.token;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.url, true);
    xhr.setRequestHeader('X-Auth-Token', _token);
    xhr.withCredentials = true;
    return xhr;
  }

  /**
   * Sends a single file in whole.
   */
  private _postFile() {
    const form = new FormData();
    form.append('file', this.file, this.file.name);

    const xhr = this._createRequest();
    // @ts-ignore
    xhr.upload.onerror = this._handleError;
    xhr.upload.onprogress = this._handleProgress;
    xhr.onload = () => {
      this.onSuccess(JSON.parse(xhr.responseText));
    };

    xhr.send(form);
  }

  /**
   * Chunk upload conductor. Splits the file and sends its parts out.
   */
  private _handleChunks(): void {
    const fileId: string = Math.random().toString(36).substring(2);
    const promiseArray = this._makeChunks().map(
      (ch: Blob, idx: number, arr: Blob[]): Promise<unknown> =>
        this._postChunk(ch, idx + 1, arr.length, fileId)
    );

    Promise.all(promiseArray)
      .then((results: string[]) => {
        const data = JSON.parse(results.find((r: string) => r !== ''));
        this.onSuccess(data);
      })
      .catch(this._handleError);
  }

  /**
   * Splits a file into several chunks.
   */
  private _makeChunks(): Blob[] {
    const chunks: Blob[] = [];
    let start = 0;
    let end: number;
    const slice = () => chunks.push(this.file.slice(start, end));

    const loop = () => {
      end = start + this.chunkSize;
      if (this.file.size - end < 0) {
        end = this.file.size;
        slice();
        return chunks;
      }

      slice();
      if (end < this.file.size) {
        start += this.chunkSize;
        loop();
      }
    };

    loop();
    return chunks;
  }

  /**
   * Sends a single chunk.
   */
  private _postChunk(
    chunk: Blob,
    chunkNumber: number,
    chunksTotal: number,
    fileId: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('chunk', chunk, this.file.name);
      form.append('chunkNumber', chunkNumber as any);
      form.append('chunksTotal', chunksTotal as any);
      form.append('fileId', fileId);
      form.append('fileName', this.file.name);
      form.append('fileSize', this.file.size as any);

      const xhr = this._createRequest();
      xhr.upload.onerror = reject;
      xhr.upload.onprogress = this._handleChunkProgress(
        chunkNumber,
        chunksTotal
      ) as any;
      xhr.onload = () => {
        /^2/.test(String(xhr.status))
          ? resolve(xhr.responseText)
          : reject(new Error(xhr.statusText));
      };

      xhr.send(form);
    });
  }

  private _handleError = (e: Error): void => this.onError(e);

  private _handleProgress = ({ total, loaded }): void =>
    this.onProgress && this.onProgress(Uploader.getProgress(loaded, total));

  /**
   * Calculates the total progress over the chunks.
   */
  private _handleChunkProgress = (
    chunkNumber: number,
    chunksTotal: number
  ): Function => ({ total, loaded }): void => {
    this.progressArray[chunkNumber - 1] = Uploader.getProgress(loaded, total);
    const progress = this.progressArray.reduce((sum, current) => sum + current);
    this.onProgress && this.onProgress(Math.floor(progress / chunksTotal));
  };
}
