import { TAquaculture, TFarmCapacity } from './Aquaculture';
import { TDeckEquipment } from './DeckEquipment';
import { TElectricalPower } from './ElectricalPower';
import { TInstallations } from './Installations';
import { TManufacturer } from '../dictionaries';
import { TPropulsionAndManeuvering } from './PropulsionAndManeuvering';

export type TVesselExtras = {
  // Propulsion and Maneuvering
  propulsionAndManeuvering?: TPropulsionAndManeuvering;
  // Electrical Power
  electricalPower?: TElectricalPower;
  // Deck Machinery and Equipment
  // fields below assume fishing vessel only.
  // can be 'null' for others.TFarmCapacity
  deckEquipment?: TDeckEquipment;
  // Ship Installations
  installations?: TInstallations;
  aquaculture?: TAquaculture;
  other?: TOtherExtras;
};

export type TOtherExtras = {
  environment: TEnvironment[];
  fishCapacity: TFarmCapacity;
};

export type TRefrigeration = TManufacturer & {
  manufacturerId: number;
  type: ERefrigeration;
  volume?: number;
  units: ECapacityUnits;
  details: string;
};

export enum ERefrigeration {
  RSW = 'RSW System',
  FREEZING = 'Freezing Facility',
  CARGO = 'Cargo Refrigeration',
  ICE_MACHINE = 'Ice Machine'
}

export enum ECapacityUnits {
  TONNS = 'Ton',
  TON = 'Tonnage (ton)',
  TON_HOUR = 'Ton/hour',
  CUBIC_METERS = 'm3',
  KILOWATTS = 'kW',
  TON_DAY = 'Ton/day',
  BOXES = 'Boxes'
}

export enum ECapacityUnits4 {
  TON_DAY = 'Ton/day',
  TON_HOUR = 'Ton/hour',
  TONS = 'Tons'
}

export type TFarmAutomation = {
  manufacturerId: number;
  type: EFarmAutomation;
};

export enum EFarmAutomation {
  SURVEILLANCE = 'Surveillance and Control',
  WELL_WASHING = 'Well Washing',
  EMS = 'EMS',
  PMS = 'PMS',
  IAS = 'IAS',
  SHIP_AUTOMATION = 'Ship Automation',
  PROPULSION_SYSTEM = 'Propulsion System',
  WATER_TREATMENT = 'Water Treatment/Ballast'
}

export type TLoadingUnloading = TManufacturer & {
  manufacturerId: number;
  type: ELoadingUnloading;
};
export enum ELoadingUnloading {
  VACUUM = 'Vacuum',
  HYDRAULIC = 'Hydraulic',
  PRESSURE = 'Pressure',
  PRESSURE_VACUUM = 'Pressure/Vacuum',
  ELEVATOR = 'Elevator',
  VARIOUS_PUMPS = 'Various pumps',
  OTHER = 'Other'
}

export type TEnvironment = {
  manufacturerId: number;
  type: EEnvironmentType;
  method: EEnvironmentMethod;
  details: string;
};
export enum EEnvironmentMethod {
  SLUDGE_SEPARATOR = 'Sludge separator',
  OILY_WATER_SEPARATOR = 'Oily water separator',
  SEPARATOR = 'Separator',
  BALLAST_TREATMENT = 'Ballast water treatment',
  SELECTIVE_CATALYTIC_REDUCTION = 'SCR (Selective Catalytic Reduction)',
  SCRUBBER = 'Scrubber'
}
export enum EEnvironmentType {
  CLEANSING_FACILITY = 'Cleansing facility',
  POLLUTION_PREVENTION = 'Pollution prevention'
}
