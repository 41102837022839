// prettier-ignore
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthoredRoute from './AuthoredRoute';
import { ENV } from '../../other/config';
import { isProdMode } from '../helpers/helpers';
import { loader } from '../common/Loading/LoadingSimple';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { ROUTES } from '../../other/constants';

import { EUserAuthority } from '../../types';

const ActivityProfilePage = lazy(() => import('../../pages/pbi/ActivityProfilePage'));
const AreaEditorPage = lazy(() => import('../../pages/areas/AreaEditorPage/AreaEditorPage'));
const AnalyticsPage = lazy(() => import('../../pages/tableau/AnalyticsPage'));
// const BiolyticsPage = lazy(() => import('../../pages/pbi/BiolyticsPage'));
const HarvestingPage = lazy(() => import('../../pages/pbi/HarvestingPage'));
const CustomReportPage = lazy(() => import('../../pages/pbi/CustomReportPage'));

const CampaignPage = lazy(() => import('../../pages/campaign/CampaignPage'));
const CampaignSelectorPage = lazy(() => import('../../pages/campaign/CampaignSelectorPage'));

const CompanyListPageConnected = lazy(() => import('../../pages/company/CompanyList/CompanyListPageConnected'));
const Company = lazy(() => import('../../pages/company/Company/CompanyPage'));
const CompareVessel = lazy(() => import('../../pages/vessel/VesselsComparison/VesselsComparison'));

const CRM = lazy(() => import('../../pages/CRM/CRM'));
const CrmCompany = lazy(() => import('../../pages/CrmCompany/CrmCompany'));
const CrmVessel = lazy(() => import('../../pages/CrmVessel/CrmVessel'));
const DisclaimerPageAF = lazy(() => import('../../pages/DisclaimerPageAF'));
const DisclaimerPageFF = lazy(() => import('../../pages/DisclaimerPageFF'));

const EventEditorPage = lazy(() => import('../../pages/events/EventEditor/EventEditorPage'));
const EventPage = lazy(() => import('../../pages/events/EventPage/EventPage'));
const EventsListPage = lazy(() => import('../../pages/events/EventsList/EventsListPage'));

const FarmPageConnected = lazy(() => import('../../pages/farm/Farm/FarmPageConnected'));
const FarmsListPageConnected = lazy(() => import('../../pages/farm/FarmsList/FarmsListPageConnected'));

const HistoricalCatch = lazy(() => import('../../pages/HistoricalCatch/HistoricalCatchPage'));
const HistoricalTrack = lazy(() => import('../../pages/HistoricalTrack/HistoricalTrackPage'));
const Home = lazy(() => import('../../pages/Home/Home'));
const Login = lazy(() => import('../../pages/Login/LoginPage'));

const NewsArticlePage = lazy(() => import('../../pages/news/NewsArticlePage/NewsArticlePage'));
const NewsEditorPage = lazy(() => import('../../pages/news/NewsEditor/NewsEditorPage'));
const NewsListPage = lazy(() => import('../../pages/news/NewsList/NewsListPage'));
const NotificationPage = lazy(() => import('../../pages/notifications/NotificationPage'));

const Page404 = lazy(() => import('../../pages/Page404/Page404'));
const PersonPage = lazy(() => import('../../pages/Person/PersonPage'));
const PlayerPage = lazy(() => import('../../pages/Player/PlayerPage'));

const PrivacyPolicyPageAF = lazy(() => import('../../pages/PrivacyPolicyPageAF'));
const PrivacyPolicyPageFF = lazy(() => import('../../pages/PrivacyPolicyPageFF'));

const ProductArticlePage = lazy(() => import('../../pages/serviceProviders/ProductPage/ProductPage'));
const ProductEditorPage = lazy(() => import('../../pages/serviceProviders/ProductEditorPage/ProductEditorPage'));

const ServiceEditorPage = lazy(() => import('../../pages/serviceProviders/ServiceEditor/ProviderEditorPage'));
const ServiceListPage = lazy(() => import('../../pages/serviceProviders/ServiceList/ServiceListPage'));
const ServicePage = lazy(() => import('../../pages/serviceProviders/Service/ServicePage'));

const ButtonPage = lazy(() => import('../../pages/TestPage/ButtonPage'));
const TestPage = lazy(() => import('../../pages/TestPage/TestPage'));
const User = lazy(() => import('../../pages/User/UserPage'));

const VesselListPageConnected = lazy(() => import('../../pages/vessel/VesselsList/VesselListPageConnected'));
const VesselPageConnected = lazy(() => import('../../pages/vessel/Vessel/VesselPageConnected'));
const VesselsMapPage = lazy(() => import('../../pages/Map/VesselsMapPage'));

const areaRoles = [// todo insert an appropriate role
];
const companyPageRoles = [EUserAuthority.VIEW_OWNER];
const CRMPageRoles = [EUserAuthority.CRM];
const customReportRoles = [
  EUserAuthority.VIEW_USER_CUSTOM_AKER_BIOMARINE,
  EUserAuthority.VIEW_USER_CUSTOM_NORDIC_WILDFISH,
  EUserAuthority.VIEW_USER_CUSTOM_VROLIJK
  
];
const isProduction = isProdMode();
const newsRoles = [EUserAuthority.CREATE_NEWS];
const serviceRoles = [EUserAuthority.SERVICE_PROVIDER];
const viewMapPermissions = [EUserAuthority.VIEW_MAP];

/**
 *
 */
class RouteSwitch extends React.PureComponent {
  render() {
    const { isLoggedIn } = this.props;
    const defaultRedirect = isLoggedIn ? ROUTES.NEWS : ROUTES.HOME;
    const HomePage = isLoggedIn ? NewsListPage : Home;
    
    return (
      <Suspense fallback={loader}>
        <Switch>
          <Route component={HomePage} exact path="/" />
          {ENV.IS_FF && (
            <Route
              component={ActivityProfilePage}
              path={ROUTES.ACTIVITY_PROFILE}
              roles={[EUserAuthority.ACTIVITY_PROFILE]}
            />
          )}
          {ENV.IS_AF && (
            <PrivateRoute
              exact
              path={ROUTES.FARMS}
              component={FarmsListPageConnected}
            />
          )}
          {ENV.IS_AF && (
            <PrivateRoute
              path={ROUTES.FARM + '/:id/:tab?/:itemId?'}
              component={FarmPageConnected}
            />
          )}
          {ENV.IS_AF && (
            <PrivateRoute
              component={CompanyListPageConnected}
              exact
              path={ROUTES.COMPANIES}
            />
          )}
          {ENV.IS_AF && (
            <Route
              component={HarvestingPage}
              path={ROUTES.HARVESTING}
            />
          )}

          <PrivateRoute path={ROUTES.VESSEL + '/:id/:tab?/:itemId?'} component={VesselPageConnected} />
          <PrivateRoute
            exact
            path={ROUTES.VESSELS}
            component={VesselListPageConnected}
          />

          <PrivateRoute component={CompareVessel} path={ROUTES.COMPARE} />
          <Route component={Login} path={ROUTES.LOGIN} />
          
          {ENV.IS_AF && (
            <Route component={DisclaimerPageAF} path={ROUTES.DISCLAIMER} />
          )}
          {ENV.IS_FF && (
            <Route component={DisclaimerPageFF} path={ROUTES.DISCLAIMER} />
          )}
          {ENV.IS_AF && (
            <Route component={PrivacyPolicyPageAF} path={ROUTES.PRIVACY_POLICY} />
          )}
          {ENV.IS_FF && (
            <Route component={PrivacyPolicyPageFF} path={ROUTES.PRIVACY_POLICY} />
          )}
          
          <PrivateRoute component={User} exact path={ROUTES.USER} />
  
          <Route
            component={HistoricalCatch}
            path={ROUTES.HISTORICAL_CATCH}
          />
          <Route
            component={HistoricalTrack}
            path={ROUTES.HISTORICAL_TRACK}
            roles={[EUserAuthority.HISTORICAL_TRACK]}
          />

          <AuthoredRoute
            component={VesselsMapPage}
            exact
            path={ROUTES.MAP}
            roles={viewMapPermissions}
          />
          <AuthoredRoute
            component={PlayerPage}
            path={ROUTES.PLAYER + '/:vesselId'}
            roles={viewMapPermissions}
          />

          <AuthoredRoute
            component={Company}
            path={ROUTES.COMPANY + '/:id/:tab?/:itemId?'}
            roles={companyPageRoles}
          />
  
          <AuthoredRoute
            component={CrmCompany}
            path={ROUTES.CRM_COMPANY + '/:id/:tab?/:itemId?'}
            roles={CRMPageRoles}
          />
  
          <AuthoredRoute
            component={CrmVessel}
            path={`${ROUTES.CRM_VESSEL}/:id/:tab?/:itemId?`}
            roles={CRMPageRoles}
          />

          <PrivateRoute
            component={CRM}
            exact
            path={`${ROUTES.CRM}/:tab?/:subTab?`}
          />
  
          <PrivateRoute component={PersonPage} path={ROUTES.PERSON + '/:id'} />
          <Route path={ROUTES.SERVICES} component={ServiceListPage} />
  
          <Route
            component={ProductEditorPage}
            path={`${ROUTES.SERVICE_PROVIDER}/:providerId/products/:productId/edit`}
          />
          <Route
            component={ProductArticlePage}
            path={`${ROUTES.SERVICE_PROVIDER}/:providerId/products/:productId`}
          />
          <Route
            component={ServicePage}
            path={`${ROUTES.SERVICE_PROVIDER}/:id`}
          />
          
          <AuthoredRoute
            component={ServiceEditorPage}
            exact
            path={ROUTES.SERVICE_EDITOR}
            roles={serviceRoles}
          />
          <AuthoredRoute
            component={ServiceEditorPage}
            path={`${ROUTES.SERVICE_EDITOR}/:id`}
            roles={serviceRoles}
          />

          <AuthoredRoute
            component={NewsEditorPage}
            exact
            path={ROUTES.NEWS_EDITOR}
            roles={newsRoles}
          />
          <AuthoredRoute
            component={NewsEditorPage}
            path={`${ROUTES.NEWS_EDITOR}/:id`}
            roles={newsRoles}
          />

          <Route
            component={NewsListPage}
            exact
            path={ROUTES.NEWS}
            roles={serviceRoles}
          />

          <Route
            component={NewsArticlePage}
            path={`${ROUTES.NEWS}/:id`}
            roles={serviceRoles}
          />

          <AuthoredRoute
            component={EventEditorPage}
            exact
            path={ROUTES.EVENT_EDITOR}
            roles={serviceRoles}
          />
          <AuthoredRoute
            component={EventEditorPage}
            path={`${ROUTES.EVENT_EDITOR}/:id`}
            roles={serviceRoles}
          />
          <Route component={EventsListPage} exact path={ROUTES.EVENTS} />
          <Route component={EventPage} path={`${ROUTES.EVENTS}/:id`} />

          <AuthoredRoute
            component={CampaignSelectorPage}
            path={ROUTES.CAMPAIGN_SELECT}
            roles={serviceRoles}
          />

          <AuthoredRoute
            component={CampaignPage}
            path={ROUTES.CAMPAIGN_EDITOR}
            roles={serviceRoles}
          />

          {/*<AuthoredRoute*/}
          <Route
            component={AreaEditorPage}
            path={`${ROUTES.AREA_EDITOR}/:id?`}
            roles={areaRoles}
          />
          
          <PrivateRoute component={NotificationPage} path={ROUTES.NOTIFICATIONS} />
          
          <PrivateRoute component={AnalyticsPage} path={ROUTES.ANALYTICS} />
          {/*<PrivateRoute component={BiolyticsPage} path={ROUTES.BIOLYTICS} />*/}
          
          {ENV.IS_FF && (
            <AuthoredRoute
              component={CustomReportPage}
              path={ROUTES.CUSTOM_REPORT}
              roles={customReportRoles}
            />
          )}
          {ENV.IS_AF && (
            <AuthoredRoute
              component={CustomReportPage}
              path={ROUTES.CUSTOM_REPORT}
              roles={[EUserAuthority.VIEW_USER_CUSTOM_SCANBIO]}
            />
          )}
          
          <Route component={Page404} path={ROUTES.PAGE404} />

          {isProduction || <Route component={TestPage} exact path={ROUTES.TEST} />}
          <Route component={ButtonPage} path={ROUTES.TEST_BUTTONS} />
          
          <Redirect to={defaultRedirect} />
        </Switch>
      </Suspense>
    );
  }
}

/**/
RouteSwitch.propTypes = { isLoggedIn: PropTypes.bool };
export { RouteSwitch };
