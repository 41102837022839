import React from 'react';
import classnames from 'classnames';
import Sider from 'antd/lib/layout/Sider';

import './CollapseAside.scss';
import { AsideContent } from './AsideContent';
import { AsideTrigger } from './AsideTrigger';
import { ROUTES } from 'other/constants';

import { EUserAuthority, TNavigationItem } from 'types';
import { TInjectedWithAuthProps, withAuth } from '../withAuth';

/**/
type Props = TInjectedWithAuthProps & {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  items: TNavigationItem[];
  pathname: string;
  toggleAside: () => void;
};

/**/
type State = {
  isHovered: boolean;
  isOpen: boolean;
};

/**/
const COLLAPSED_ASIDE_ROUTES = [
  ROUTES.HISTORICAL_CATCH,
  ROUTES.MAP,
  ROUTES.PLAYER
];

/**
 *
 */
class Component extends React.PureComponent<Props, State> {
  state: State = {
    isHovered: false,
    isOpen: false
  };

  /**/
  isMapLayout(): boolean {
    const { pathname, userInfo } = this.props;
    const right = userInfo.authorities?.includes(
      EUserAuthority.HISTORICAL_TRACK
    );

    return (
      COLLAPSED_ASIDE_ROUTES.includes(pathname) ||
      (ROUTES.HISTORICAL_TRACK === pathname && right) ||
      pathname.includes(ROUTES.PLAYER)
    );
  }

  /**/
  handleToggleOpen = () =>
    this.setState({
      isOpen: !this.state.isOpen
    });

  /**/
  handleEnter = () => this.setState({ isHovered: true });

  /**/
  handleLeave = () => this.setState({ isHovered: false });

  /**/
  render() {
    const { isAsideOpen, isLoggedIn, items, toggleAside } = this.props;
    const isMap = this.isMapLayout();
    const handler = isMap ? this.handleToggleOpen : toggleAside;

    const isOpen =
      (isMap ? this.state.isOpen : isAsideOpen) ||
      this.state.isHovered ||
      !isLoggedIn;

    const siderCls = classnames('CollapseAside', {
      'CollapseAside--open': isMap ? this.state.isOpen : isAsideOpen,
      'CollapseAside--map': isMap
    });

    return (
      <>
        {isMap && <div className="CollapseAside__place" />}

        <Sider
          className={siderCls}
          collapsible
          collapsed={!isOpen}
          trigger={null}
        >
          <AsideTrigger isOpen={isOpen} toggleAside={handler} />

          <AsideContent
            isLoggedIn={isLoggedIn}
            isOpen={isOpen}
            items={items}
            onMouseEnter={this.handleEnter}
            onMouseLeave={this.handleLeave}
          />
        </Sider>
      </>
    );
  }
}

/**
 *
 */
export const CollapseAside = withAuth(Component);
