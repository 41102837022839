import { API_URL, ENDPOINTS, SUB_POINTS } from 'other/config';
import { getToken } from 'services/auth';
import { Uploader } from 'other/Uploader';

import { EBannerType, TMedia } from 'types';

export class BannerUploader {
  private readonly onStart;
  private readonly onProgress;
  private readonly onSuccess;
  private readonly onError;
  private readonly type: EBannerType;

  constructor(
    type: EBannerType,
    onStart: (type: EBannerType) => void,
    onProgress: (type: EBannerType, progress: number) => void,
    onSuccess: (type: EBannerType, data: TMedia) => void,
    onError: (type: EBannerType, message: string) => void
  ) {
    this.type = type;
    this.onStart = () => onStart(type);
    this.onProgress = (progress: number) => onProgress(type, progress);
    this.onSuccess = (response) => onSuccess(type, response.data);
    this.onError = (message: string) => onError(type, message);
  }

  upload(file: File, promoId: number) {
    const url = `${API_URL}${ENDPOINTS.PROMOTION}/${promoId}${SUB_POINTS.BANNER}?type=${this.type}`;
    const uploader = new Uploader({
      onError: this.onError,
      onProgress: this.onProgress,
      onSuccess: this.onSuccess,
      token: getToken
    });

    this.onStart();
    uploader.upload(file, url);
  }
}
