import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox';
import Form, { FormProps } from 'antd/lib/form';
import Input from 'antd/lib/input';

import './LoginForm.scss';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import { LinkButton } from 'components/common/buttons/LinkButton/LinkButton';
import { minMaxLenValidator } from 'other/formAndValidation/validators';
import { PrimaryButton } from 'components/common/buttons/PrimaryButton/PrimaryButton';
import { TMouseHandler } from 'types';

/**/
export const loginRules = minMaxLenValidator('User name', 50, 2);
export const passwordRules = minMaxLenValidator('Password', 50, 6);

/**/
type Props = FormProps & {
  /**
   * Whether the application is capable of storing credentials.
   * Used to render the `Keep me logged` checkbox.
   */
  canStoreCredentials: boolean;
  /**  assumed to display credentials or network error message arop of the form. */
  displayError: string;
  /** a pending flag. */
  isPending: boolean;
  /** on the &apos;Forgot password?&apos; link click handler. */
  onPasswordRestore?: TMouseHandler;
  /** on form submit callback function. */
  onSubmit: (
    username: string,
    password: string,
    shouldKeepLogged: boolean
  ) => void;
};

/**
 * Login form component.
 * @param props
 * @constructor
 */

export const LoginForm = (props: Props): ReactElement => {
  const {
    canStoreCredentials,
    displayError,
    isPending,
    onPasswordRestore,
    onSubmit
  } = props;
  const initial = { shouldKeepLogged: true };

  const handleSubmit = ({ username, password, shouldKeepLogged }) => {
    isPending || onSubmit(username, password, shouldKeepLogged);
  };

  const keepLogged: ReactNode = (
    <Form.Item name="shouldKeepLogged" valuePropName="checked">
      <Checkbox className="LoginForm__checkbox">Remember me</Checkbox>
    </Form.Item>
  );

  return (
    <Form className="LoginForm" onFinish={handleSubmit} initialValues={initial}>
      <h1 className="app-h1 LoginForm__title">Log in</h1>
      {displayError && (
        <p className="app-error-msg LoginForm__error">{displayError}</p>
      )}

      <Form.Item name="username" rules={loginRules} validateTrigger="onBlur">
        <Input placeholder="Username" size="large" />
      </Form.Item>

      <Form.Item name="password" rules={passwordRules} validateTrigger="onBlur">
        <Input.Password placeholder="Password" size="large" />
      </Form.Item>

      <div className="LoginForm__row">
        {canStoreCredentials && keepLogged}
        {onPasswordRestore && (
          <LinkButton className="app-btn-reset" onClick={onPasswordRestore}>
            Forgot password?
          </LinkButton>
        )}
      </div>

      <PrimaryButton className="LoginForm__submit" htmlType="submit" size="M">
        {isPending ? <IconCustom type="loading" /> : 'Submit'}
      </PrimaryButton>
    </Form>
  );
};

/**/
LoginForm.propTypes = {
  canStoreCredentials: PropTypes.bool,
  displayError: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  onPasswordRestore: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};
