import PropTypes from 'prop-types';

import { TMedia } from './media';
import { TLanguageISO } from './dictionaries';

export type TArticle = {
  analytics: TArticleAnalytic;
  banner: TMedia;
  hasPromotion: boolean;
  id: number;
  languageIso?: string; // ISO representation
  language?: TLanguageISO;
  logo: TMedia;
  longDescriptionMedia?: TMedia;
  longDescription: string;
  published: boolean;
  shortDescription: string;
  source: TArticleSource;
  time: string;
  title: string;
};

export enum TArticleOwner {
  SERVICE_PROVIDER = 'SERVICE_PROVIDER'
}

export type TArticleSource = {
  id: number;
  type: TArticleOwner.SERVICE_PROVIDER;
  name: string;
  logo: TMedia;
};

export type TArticleAnalytic = {
  impressions: number;
  shares: number;
  uniqueViews: number;
  views: number;
};

export const PTArticleAnalytic = {
  impressions: PropTypes.number,
  shares: PropTypes.number,
  uniqueViews: PropTypes.number,
  views: PropTypes.number
};
