import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './PrimaryButton.scss';
import { AppButton } from '../helpers';

/**/
type Props = Omit<ButtonProps, 'size'> & {
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL';
};

/**
 *
 */
export function PrimaryButton(props: Props) {
  return <AppButton mainClassName="PrimaryButton" type="primary" {...props} />;
}
