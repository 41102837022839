import { AnyAction } from 'redux';

import { appInitialState } from '../appInitialState';
import { RESET_STORE } from './rootConstants';
import { TState } from '../appStateModel';

export function rootReducer(state: TState, action: AnyAction): TState {
  switch (action.type) {
    case RESET_STORE: {
      return { ...appInitialState, router: state.router };
    }

    default:
      return state;
  }
}
