import createAsyncActions from 'store/_utils/createAsyncActions';
import { TMapEntitiesState } from './mapEntitiesModel';

export enum EMapEntitiesActions {
  ADD_ENTITY = 'mapEntities/ADD_ENTITY',
  REMOVE_ENTITY = 'mapEntities/REMOVE_ENTITY',
  UPDATE_DRAGGABLE_ENTITY = 'mapEntities/UPDATE_DRAGGABLE_ENTITY',

  ADD_LOCATION = 'mapEntities/ADD_LOCATION',

  FETCH_HIDDEN_VESSEL = 'mapEntities/FETCH_HIDDEN_VESSEL',
  FETCH_LOCATION_VALUE = 'mapEntities/FETCH_LOCATION_VALUE',
  HIDE_LOCATION_VALUE = 'mapEntities/HIDE_LOCATION_VALUE',
  REMOVE_VESSELS = 'mapEntities/REMOVE_VESSELS',
  RESET_STATE = 'mapEntities/RESET_STATE',
  PURGE_ENTITIES = 'mapEntities/PURGE_ENTITIES',
  SELECT_FARM = 'mapEntities/SELECT_FARM',
  SELECT_VESSEL = 'mapEntities/SELECT_VESSEL',
  SELECT_SERVICE = 'mapEntities/SELECT_SERVICE',
  UPDATE_ENTRIES_ON_DROP = 'mapEntities/UPDATE_ENTRIES_ON_DROP',
  UPDATE_ENTRIES_BY_TYPE = 'mapEntities/UPDATE_ENTRIES_BY_TYPE'
}

export const fetchSet = createAsyncActions<TMapEntitiesState>(
  EMapEntitiesActions.FETCH_HIDDEN_VESSEL
);

export const fetchLocationValueSet = createAsyncActions<TMapEntitiesState>(
  EMapEntitiesActions.FETCH_LOCATION_VALUE
);
