import PropTypes from 'prop-types';

export type TLocaleSet = {
  en_GB: string;
  fo_FO: string;
  is_IS: string;
  no_NO: string;
};

export const PTLocaleSet = {
  en_GB: PropTypes.string,
  fo_FO: PropTypes.string,
  is_IS: PropTypes.string,
  no_NO: PropTypes.string
};

export type TIdLocationDict = {
  id: number;
  value: TLocaleSet;
};

export const PTIdLocationDict = {
  id: PropTypes.number,
  value: PropTypes.exact(PTLocaleSet)
};

export type TIdName = {
  id: number;
  name: string;
};

export type TId = {
  id: number;
};

export type TSelectOption<T extends number | string> = {
  label: string;
  value: T;
};

export type TMinMax = {
  max: number;
  min: number;
};

export enum ECurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  DKK = 'DKK', // Danish Krone
  NOK = 'NOK', // Norwegian Krone
  SEK = 'SEK', // Swedish Krone
  GBP = 'GBP' // Pounds
}

export const PTUploadFile = {
  fileName: PropTypes.string,
  lastModified: PropTypes.number,
  lastModifiedDate: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  originFileObj: PropTypes.exact({
    RcFile: PropTypes.object,
    arrayBuffer: PropTypes.func,
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    size: PropTypes.number,
    slice: PropTypes.func,
    stream: PropTypes.func,
    text: PropTypes.func,
    type: PropTypes.string,
    uid: PropTypes.string
  }),
  percent: PropTypes.number,
  preview: PropTypes.string,
  size: PropTypes.number.isRequired,
  thumbUrl: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string.isRequired,
  url: PropTypes.string
};

export type TCreatedBy = {
  firstName?: string;
  id: number;
  lastName?: string;
  username?: string;
};

export const PTCreatedBy = {
  firstName: PropTypes.string,
  id: PropTypes.number.isRequired,
  lastName: PropTypes.string,
  username: PropTypes.string
};
