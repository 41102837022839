import React from 'react';
import ConfigProvider from 'antd/lib/config-provider';
import { connect } from 'react-redux';
import { captureException, withScope } from '@sentry/browser';
import { ThemeConfig } from 'antd/lib/config-provider/context';

import { LoaderOverlay } from 'components/common/overlays/LoaderOverlay/LoaderOverlay';
import { Main } from 'components/Main/Main';
import { prefetchDataAction } from 'store/dictionaries/dictionariesActions';
import { setTimerTrialModalAction } from 'store/trialModal/trialModalActions';

import { TState } from 'store/appStateModel';

/**/
type Props = {
  isLoggedIn: boolean;
  isSessionPending: boolean;
  prefetchData: () => void;
  setTimerTrialModal: () => void;
  theme: ThemeConfig;
};

/**/
const getPopupContainerGlobal = (node) =>
  node ? node.parentNode : document.getElementById('app');

/**
 *
 */
class App extends React.Component<Props> {
  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) =>
        scope.setExtra(key, errorInfo[key])
      );
      captureException(error);
    });
  }

  render() {
    return (
      <ConfigProvider
        getPopupContainer={getPopupContainerGlobal}
        theme={this.props.theme}
      >
        <LoaderOverlay className="App" isLoading={this.props.isSessionPending}>
          <Main {...this.props} />
        </LoaderOverlay>
      </ConfigProvider>
    );
  }
}

/**
 *
 */
const mapDispatchToProps = {
  prefetchData: prefetchDataAction,
  setTimerTrialModal: setTimerTrialModalAction
};

const mapStateToProps = ({ misc, session }: TState) => {
  const { isPending, user } = session;
  return {
    isLoggedIn: !!(user && user.token),
    isSessionPending: isPending,
    theme: misc.theme.settings
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
