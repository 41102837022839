import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import './Login.scss';
import { canSaveToLocalStorage } from 'services/storage';
import { LoaderOverlay } from 'components/common/overlays/LoaderOverlay/LoaderOverlay';
import { loginAction } from 'store/login/loginActions';
import { LoginForm } from './LoginForm/LoginForm';
import { ROUTES } from 'other/constants';

import { TState } from 'store/appStateModel';

/**/
type Props = {
  displayError: string;
  isLoggedIn: boolean;
  isPending: boolean;
  isSessionError: boolean;
  onSubmit: (
    username: string,
    password: string,
    shouldKeepLogged: boolean
  ) => void;
};

/**
 *
 */
class LoginPage extends React.Component<Props> {
  static propTypes;
  private canStoreCredentials: boolean = canSaveToLocalStorage();

  render(): ReactElement {
    const { displayError, isLoggedIn, isPending, isSessionError, onSubmit } =
      this.props;
    const shouldShowSpinner = isSessionError && isPending;
    if (isLoggedIn) return <Redirect to={ROUTES.HOME} />;

    return (
      <LoaderOverlay
        className="app-page-narrow LoginPage"
        isLoading={shouldShowSpinner}
      >
        <LoginForm
          canStoreCredentials={this.canStoreCredentials}
          displayError={displayError}
          isPending={isPending}
          onSubmit={onSubmit}
        />
      </LoaderOverlay>
    );
  }
}

/**
 *
 */
LoginPage.propTypes = {
  displayError: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  isSessionError: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ login, session }: TState) => ({
  displayError: login.displayError,
  isLoggedIn: !!session.user,
  isPending: login.isPending,
  isSessionError: !!session.error
});

const mapDispatchToProps = { onSubmit: loginAction };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
