import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Switch from 'antd/lib/switch';

import './ThemeToggle.scss';
import { GA } from 'services/analytics';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import { setTheme } from 'store/misc/miscActions';
import { TState } from 'store/appStateModel';

/**/
type Props = {
  isDark: boolean;
  setTheme: (isDark: boolean) => void;
};

const checkedIcon = (
  <IconCustom
    className="ThemeToggle__icon ThemeToggle__icon--checked"
    type="moon"
  />
);
const uncheckedIcon = (
  <IconCustom
    className="ThemeToggle__icon ThemeToggle__icon--unchecked"
    type="sun"
  />
);

/**
 *
 */
class ThemeToggle extends React.PureComponent<Props> {
  handleToggleTheme = () => {
    const { isDark, setTheme } = this.props;
    setTheme(!isDark);
  };

  render() {
    const { isDark } = this.props;
    const cls = classnames('ThemeToggle__switch', {
      'ThemeToggle__switch--checked': isDark
    });

    return (
      <div className="ThemeToggle">
        <Switch
          checked={isDark}
          checkedChildren={checkedIcon}
          className={cls}
          onChange={this.handleToggleTheme}
          unCheckedChildren={uncheckedIcon}
        />
      </div>
    );
  }
}

/**
 *
 */
const mapDispatchToProps = (dispatch) => ({
  setTheme: (isDark: boolean) => {
    dispatch(setTheme(isDark));
    isDark && GA.reportDarkMode();
  }
});

const mapStateToProps = ({ misc: { theme } }: TState) => ({
  isDark: theme.isDark
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggle);
