import React from 'react';
import classnames from 'classnames';

import './EmptyNotificationList.scss';
import { PLACEHOLDER } from 'other/constants';

/**/
type Props = {
  className?: string;
};

/**
 *
 */
function EmptyNotificationList({ className }: Props) {
  const cls = classnames('EmptyNotificationList', className);

  return (
    <div className={cls}>
      <div className="EmptyNotificationList__content">
        <img
          src={PLACEHOLDER.NOTIFICATION}
          alt="No items"
          className="EmptyNotificationList__image"
        />

        <span className="app-body-1">You have no notifications</span>
      </div>
    </div>
  );
}

/**/
export { EmptyNotificationList };
