import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './LinkButton.scss';
import { AppButton } from '../helpers';

/**/
type Props = Omit<ButtonProps, 'size'> & {
  size?: 'XS' | 'M';
};

/**
 *
 */
export function LinkButton({ size, ...props }: Props) {
  return (
    <AppButton
      mainClassName="LinkButton"
      size={size || 'M'}
      type="link"
      {...props}
    />
  );
}
