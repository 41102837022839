import createAsyncActions from 'store/_utils/createAsyncActions';
import { TVesselsState } from './vesselsModel';

export enum EVesselsActions {
  FETCH_VESSELS = 'vessels/FETCH_VESSELS',
  ASSIGN_HISTORICAL_VESSEL = 'vessels/ASSIGN_HISTORICAL_VESSEL'
}

export const fetchSet = createAsyncActions<TVesselsState>(
  EVesselsActions.FETCH_VESSELS
);
