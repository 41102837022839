import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { Messaging } from '@firebase/messaging';

import { firebaseConfig, registerSW, vapidKey } from './helpers';
import { sendToken, setTokenSent } from './apiCalls';

/**
 *
 */
export function getFirebaseToken(messaging): Promise<unknown> {
  return getToken(messaging, { vapidKey })
    .then((token: string) => {
      window.console.log('FCM token:\n', token);
      if (token) return sendToken(token);

      window.console.log('No registration token available.');
      setTokenSent(false);
    })
    .catch((err) => {
      window.console.log(err);
      setTokenSent(false);
    });
}

/**
 *
 */
export function runPushService(authToken: string) {
  return null;

  // if (isTestRunning()) return;
  //
  // if (!('Notification' in window)) {
  //   return (window as any).console.warn('Notification API is not supported.');
  // }
  //
  // if (Notification.permission === 'granted') {
  //   bootstrap(authToken);
  //   /**/
  // } else if (Notification.permission !== 'denied') {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  //       bootstrap(authToken);
  //     } else {
  //       window.console.log('Unable to get Notification permission.');
  //     }
  //   });
  // }
}

/**
 *
 */
function bootstrap(authToken: string) {
  const messaging = init();
  subscribe(messaging);

  getFirebaseToken(messaging);
  // .then(() => {
  // registerSW(authToken);
  // });
}

/**
 *
 */
function init(): Messaging {
  const app = initializeApp(firebaseConfig);
  return getMessaging(app);
}

/**
 *
 */
function subscribe(messaging) {
  // window.console.log('Subscribing');
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.
  onMessage(messaging, (payload) => {
    window.console.log('onMessage. ', payload);
    window.navigator.serviceWorker.ready
      .then((registration) => {
        payload.notification['data'] = {
          body: payload.notification.body,
          image: payload.notification.image,
          title: payload.notification.title
        };

        window.console.log(
          'onMessage - serviceWorker.ready',
          payload.notification['data']
        );
        // @ts-ignore
        const path = payload.notification.data.click_action || '/services';

        registration.showNotification('onMsg: ' + payload.notification.title, {
          body: payload.notification.body,
          data: { click_action: path },
          icon: payload.notification.image
        });
      })
      .catch((error) =>
        window.console.log('ServiceWorker registration failed', error)
      );
  });
}

/**
 *
 */
function isTestRunning() {
  return process.env.JEST_WORKER_ID !== undefined;
}
