import createAsyncActions from 'store/_utils/createAsyncActions';
import { TTracksState } from './tracksModel';

export enum ETracksActions {
  FETCH_TRACK = 'tracks/FETCH_TRACK',
  CHANGE_TRACK_PERIOD = 'tracks/CHANGE_TRACK_PERIOD',
  REMOVE_TRACK = 'tracks/REMOVE_TRACK',
  PURGE_TRACKS = 'tracks/PURGE_TRACKS',
  REMOVE_TRACK_POINT = 'tracks/REMOVE_TRACK_POINT',
  REMOVE_TRACK_POINTS = 'tracks/REMOVE_TRACK_POINTS',
  UPDATE_TRACK_POINTS = 'tracks/UPDATE_TRACK_POINTS',
  APPEND_TRACK_POINT = 'tracks/APPEND_TRACK_POINT',

  CREATE_TRACK_VECTOR = 'tracks/CREATE_TRACK_VECTOR',
  REMOVE_TRACK_VECTOR = 'tracks/REMOVE_TRACK_VECTOR',
  UPDATE_TRACK_VECTORS = 'tracks/UPDATE_TRACK_VECTORS'
}

export const trackSet = createAsyncActions<TTracksState>(
  ETracksActions.FETCH_TRACK
);
