import { RuleType } from 'rc-field-form/es/interface';

export const MESSAGES = {
  INVALID_EMAIL: 'You entered an invalid email. Please try again!',
  PLACEHOLDER: 'Enter your email',
  REQUIRED_FIELD: 'Email is required field!',
  RESPONSE_ERROR: 'Connection problem. Please try again!'
};

export const RULES = [
  {
    message: MESSAGES.REQUIRED_FIELD,
    required: true
  },
  {
    message: MESSAGES.INVALID_EMAIL,
    type: 'email' as RuleType
  }
];

export const TIMER_DELAY = 10000;
