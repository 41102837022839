export type TDD = {
  lat: string;
  lng: string;
};

export type TDDM = {
  lat: {
    deg: string;
    min: string;
    hem: 'S' | 'N';
  };
  lng: {
    deg: string;
    min: string;
    hem: 'E' | 'W';
  };
};

export type TDMS = {
  lat: {
    deg: string;
    min: string;
    sec: string;
    hem: 'S' | 'N';
  };
  lng: {
    deg: string;
    min: string;
    sec: string;
    hem: 'E' | 'W';
  };
};

export enum ECoordinate {
  DD = 'DD',
  DDM = 'DDM',
  DMS = 'DMS'
}

// export enum ECoordinate {
//   DD = 'Decimal Degrees (DD)',
//   DDM = 'Degrees Decimal Minutes (DDM)',
//   DMS = 'Degrees Minutes Seconds (DMS)'
// }
