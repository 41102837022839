import { push } from 'connected-react-router';

import { assignHistoricalVessel } from '../vessel/vessels/vesselsActions';
import { getItemRoute, SearchUtil } from './SearchUtil';
import { http } from 'services/http';
import { isMap } from 'components/helpers/helpers';
import { ROUTES } from 'other/constants';
import store, { history } from 'store/store';
import { watchLocationsAction } from '../map/actionMediator';

import { ESearchCategory, TSearchChoice, TSearchResult } from 'types';
import { ESearchActions, searchSet } from './searchConstants';
import { TAction } from 'store/_utils/reducerCreator';
import { THttpResponse } from 'services/HttpClass';
import { TSearchState } from './searchModel';
import { TState } from 'store/appStateModel';

/**
 * Fired on search keystroke.
 * @param searchQuery
 */
export function searchRequestAction(searchQuery: string) {
  return (dispatch, getState) => {
    const {
      dictionaries: { companyStatuses, fishFarmStatuses, vesselStatuses },
      session: { user }
    }: TState = getState();

    const util = new SearchUtil(
      user?.userInfo.authorities,
      companyStatuses,
      fishFarmStatuses,
      vesselStatuses
    );
    dispatch(searchTypedAction(searchQuery));

    if (!searchQuery) return;
    dispatch(searchSet.request());

    http
      .send(SearchUtil.getRequestParams(searchQuery))
      .then(({ data }: THttpResponse<TSearchResult[]>) =>
        dispatch(
          searchSet.success({
            searchResults: util.getPayload(data)
          })
        )
      )
      .catch((e) => dispatch(searchSet.error(e)));
  };
}

/**
 * Fired when user selects an item from obtained search result.
 * @param item
 */
export function selectSearchResultAction(item: TSearchChoice) {
  return (dispatch) => {
    const { id, type } = item;

    // If current page is the map, and we're looking for a vessel, we want the vessel to be highlighted.
    if (isMap()) {
      if (type === ESearchCategory.FARM) {
        dispatch(watchLocationsAction(true));
        return dispatch(push({ search: '?farm=' + id }));
      }
      if (type === ESearchCategory.VESSEL) {
        dispatch(watchLocationsAction(true));
        return dispatch(push({ search: '?vessel=' + id }));
      }
    }

    const isPlayerPage = window.location.pathname.includes(ROUTES.PLAYER);
    if (isPlayerPage && type === ESearchCategory.VESSEL) {
      return dispatch(push(`${ROUTES.PLAYER}/${id}`));
    }

    if (
      window.location.pathname === ROUTES.HISTORICAL_TRACK &&
      type === ESearchCategory.VESSEL
    ) {
      dispatch(searchTypedAction());
      return dispatch(assignHistoricalVessel(parseInt(id)));
    }

    // Otherwise, just go to an item's page.
    dispatch(push(getItemRoute(item)));
  };
}

/**
 * Reports what text has been typed. Also used to clear search results.
 * @param searchQuery
 */
export const searchTypedAction = (
  searchQuery = ''
): TAction<TSearchState, ESearchActions> => ({
  type: ESearchActions.SEARCH_TYPED,
  payload: {
    searchQuery: searchQuery,
    searchResults: []
  }
});

/**/
let prevLocation = {} as any;
/**
 * Listens to the router and clears search query (if any) on changes.
 */
export function clearSearch() {
  history.listen((nextLocation) => {
    if (
      prevLocation.pathname !== nextLocation.pathname ||
      prevLocation.search !== nextLocation.search
    ) {
      const { search } = store.getState() as TState;
      search.searchQuery !== '' && store.dispatch(searchTypedAction());
    }

    prevLocation = nextLocation;
  });
}
