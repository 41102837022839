export enum ESearchCategory {
  COMPANY = 'COMPANY',
  FARM = 'FARM',
  PERSON = 'PERSON',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  VESSEL = 'VESSEL'
}

export type TSearchResult = {
  type: ESearchCategory;
  items: ReadonlyArray<TSearchItem>;
};

export type TSearchResultExt = {
  type: ESearchCategory;
  items: ReadonlyArray<TSearchItemExt>;
};

export type TSearchItem = {
  id: number;
  statusId: number;
  text: string;
};

// Besides text, one of the two should be defined, path or type-id couple.
export type TSearchItemExt = {
  id?: number;
  path?: string;
  text: string;
  type?: ESearchCategory;
};

export type TSearchChoice = {
  id?: string;
  path?: string;
  text: string;
  type?: ESearchCategory;
};
