import { ENDPOINTS } from 'other/config';
import { http } from '../http';
import { SettingsService } from '../settings';

/**/
const STORAGE_KEY = SettingsService.IS_FIREBASE_TOKEN_SENT;

/**
 *
 */
export function sendToken(token: string) {
  if (isTokenSent()) {
    return new Promise((r) => r(null));
  }

  return http
    .send({
      body: { token },
      method: 'PUT',
      url: ENDPOINTS.PUSH_NOTIFICATION_TOKEN
    })
    .then((resp) => {
      setTokenSent(true);
      // window.console.log(resp);
      window.addEventListener('beforeunload', () => removeToken(token));
    })
    .catch((e) => {
      setTokenSent(false);
      window.console.error(e);
    });
}

/**
 *
 */
function removeToken(token: string) {
  http
    .send({
      method: 'DELETE',
      url: ENDPOINTS.PUSH_NOTIFICATION_TOKEN + '/' + token
    })
    .then((resp) => {
      setTokenSent(false);
      window.console.log(resp);
    })
    .catch(window.console.error);
}

/**
 *
 */
function isTokenSent(): boolean {
  return SettingsService.readSettings()[STORAGE_KEY] === 'true';
}

/**
 *
 */
export function setTokenSent(isSent: boolean): void {
  SettingsService.writeSettings({ [STORAGE_KEY]: isSent });
}
