import PropTypes from 'prop-types';

import { PTVesselType, TVesselType } from '../dictionaries';
import { TFarm } from '../farm';
import { TId } from '../general';
import { TVessel, PTVessel } from '../vessel';

export enum EEntityType {
  COMPANY = 'COMPANY',
  FISHFARM = 'FISHFARM',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  VESSEL = 'VESSEL'
}

export type TTargetEntity = {
  country: string;
  id: number;
  name: string;
  statusId: number;
  type: EEntityType;
  vesselType?: TVesselType;
  vesselTypes?: TId[];
};

export const PTTargetEntity = {
  country: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  statusId: PropTypes.number,
  type: PropTypes.oneOf(Object.values(EEntityType)),
  vesselType: PropTypes.exact(PTVesselType),
  vesselTypes: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number
    })
  )
};

export type TCrmFarm = TFarm & {
  key: string;
  statusId?: number;
};

export type TCrmVessel = TVessel & {
  key: string;
  statusId?: number;
};

export const PTCrmVessel = {
  ...PTVessel,
  key: PropTypes.string.isRequired,
  statusId: PropTypes.number
};
