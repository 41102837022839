import React from 'react';

import { APP_CONTENT, ROUTES } from 'other/constants';
import { BurgerAside } from '../Aside/BurgerAside';
import { CollapseAside } from '../Aside/CollapseAside';
import { CookieConsentCustom } from 'components/CookieConsentCustom/CookieConsentCustom';
import { ENV } from 'other/config';
import { Header } from '../Header/Header';
import { RouteSwitch } from '../routing/RouteSwitch';

/**/
type Props = {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  pathname: string;
  toggleAside: () => void;
};

/**
 *
 */
function MainContainer(props: Props) {
  const { isAsideOpen, isLoggedIn, pathname, toggleAside } = props;
  const items = isLoggedIn ? ENV.NAVIGATION : ENV.NAVIGATION_ANON;
  const hideAside = !isLoggedIn && pathname === ROUTES.HOME;

  return (
    <>
      <Header
        isAsideOpen={isAsideOpen}
        isLoggedIn={isLoggedIn}
        toggleAside={toggleAside}
      />

      <div className="Main__container">
        <BurgerAside {...props} items={items} />

        {/*@ts-ignore*/}
        {hideAside || <CollapseAside {...props} items={items} />}

        <div className="Main__content" id={APP_CONTENT}>
          <RouteSwitch isLoggedIn={isLoggedIn} />
          <CookieConsentCustom />
        </div>
      </div>
    </>
  );
}

/**
 *
 */
export { MainContainer };
