import isequal from 'lodash.isequal';
import { push } from 'connected-react-router';

import {
  appendTrackPoint,
  createVector,
  isPointNewer,
  isPointValid
} from './helpers';
import { ENDPOINTS } from 'other/config';
import { GA } from 'services/analytics';
import { http } from 'services/http';
import { ROUTES } from 'other/constants';
import { SettingsService } from 'services/settings';

import { ETracksActions, trackSet } from './tracksConstants';
import {
  ETrackPeriod,
  TTrackPoint,
  TVesselLocation,
  TVesselLocationExt,
  TVesselTrack,
  TVesselTrackData,
  TVesselTrackPoint,
  TVesselTrackVector
} from 'types';
import { TAction } from '../../_utils/reducerCreator';
import { THttpResponse } from 'services/HttpClass';
import { TState } from '../../appStateModel';
import { TTracksState } from './tracksModel';

/**
 * Retrieves a track record for a specified vessel. On response swaps a stale record if any.
 */
export function fetchTrackAction(vesselId: number, isUpdate?: boolean) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    const url = `${ENDPOINTS.VESSELS}/${vesselId}/pathEx?trackType=${tracks.trackPeriod}`;
    dispatch(trackSet.request());

    http
      .send(url)
      .then(({ data }: THttpResponse<TVesselTrackData>) => {
        let update: TVesselTrack[];
        const {
          tracks: { tracks = [] }
        } = getState() as TState;

        if (isUpdate) {
          update = tracks.map((tr: TVesselTrack) => {
            if (tr.vesselId !== vesselId) return tr;
            return {
              ...data,
              vesselId: vesselId
            };
          });
        } else {
          const isContained = !!tracks.find(
            (t: TVesselTrack) => t.vesselId === vesselId
          );
          const item = {
            ...data,
            vesselId: vesselId
          };
          update = isContained
            ? tracks.map((t: TVesselTrack) =>
                t.vesselId === vesselId ? item : t
              )
            : [...tracks, item];
        }

        dispatch(trackSet.success({ tracks: update }));
        dispatch(createTrackVectorAction(vesselId, data.locations));
      })
      .catch((e) => dispatch(trackSet.error(e)));
  };
}

/**
 * Updates the track records with respect to given track period.
 */
export function changeTrackPeriodAction(trackPeriod: ETrackPeriod) {
  return (dispatch, getState) => {
    const {
      tracks: { tracks = [] }
    } = getState() as TState;

    window.location.pathname.startsWith(ROUTES.MAP) &&
      GA.reportTrackPeriod(trackPeriod);

    dispatch({
      type: ETracksActions.CHANGE_TRACK_PERIOD,
      payload: {
        trackPoints: [],
        trackPeriod: trackPeriod
      }
    } as TAction<TTracksState, ETracksActions>);

    SettingsService.writeSettings({
      [SettingsService.TRACK_PERIOD]: trackPeriod
    });

    tracks.forEach(({ vesselId }: TVesselTrack) =>
      dispatch(fetchTrackAction(vesselId, true))
    );
  };
}

/**
 * Removes the track record of a given vessel.
 */
export function removeTrackDataAction(vesselId: number) {
  return (dispatch, getState) => {
    const {
      tracks: { tracks }
    } = getState() as TState;

    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.REMOVE_TRACK,
      payload: {
        tracks: tracks.filter((tr: TVesselTrack) => tr.vesselId !== vesselId)
      }
    };

    dispatch(action);
    dispatch(updateTrackPointsAction(null, vesselId));
    dispatch(removeTrackVectorAction(vesselId));
  };
}

/**
 * Removes all the tracks.
 */
export function purgeTracksAction() {
  return (dispatch) => {
    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.PURGE_TRACKS,
      payload: {
        tracks: [],
        vectors: []
      }
    };
    dispatch(action);
    // dispatch(removeVesselEntriesAction());
  };
}

/**
 * Updates track points.
 * @param trackPoint - object to be pushed to the array.
 * @param id - the ID of vessel points of which we want to delete.
 */
export function updateTrackPointsAction(trackPoint: TTrackPoint, id?: number) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    let points;

    // multiple popups are closed, means the whole track is removed
    if (id) {
      points = tracks.trackPoints.filter(
        ({ vesselId }: TTrackPoint) => vesselId !== id
      );

      // track is clicked, will show a popup if there isn't.
    } else {
      const isIn = tracks.trackPoints.some(
        (p: TTrackPoint) => p.point.id === trackPoint.point.id
      );
      if (isIn) {
        return dispatch(removeTrackPointAction(trackPoint.point.id));
      }

      points = [...tracks.trackPoints, trackPoint];
    }

    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.UPDATE_TRACK_POINTS,
      payload: { trackPoints: points }
    };
    dispatch(action);
  };
}

/**/
export function removeTrackPointAction(id: number) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    const update = tracks.trackPoints.filter(
      (p: TTrackPoint) => p.point.id !== id
    );

    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.REMOVE_TRACK_POINT,
      payload: { trackPoints: update }
    };
    dispatch(action);
  };
}

/**/
export function removeTrackPointsAction() {
  return (dispatch) => {
    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.REMOVE_TRACK_POINTS,
      payload: { trackPoints: [] }
    };
    dispatch(action);
  };
}

/**/
export function updateTracksAction(locations: TVesselLocation[]) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;

    const update = tracks.tracks.map((t: TVesselTrack): TVesselTrack => {
      const target = locations.find(
        (l: TVesselLocation) => l.id === t.vesselId
      );
      return isPointNewer(t, target) ? appendTrackPoint(t, target) : t;
    });

    if (!isequal(tracks.tracks, update)) {
      dispatch({
        type: ETracksActions.APPEND_TRACK_POINT,
        payload: { tracks: update }
      });

      dispatch(updateTrackVectorsAction(locations));
    }
  };
}

/**/
export function createTrackVectorAction(
  vesselId: number,
  track: TVesselTrackPoint[]
) {
  return (dispatch, getState) => {
    if (track.length === 0) return;

    const point = track[track.length - 1];
    if (!isPointValid(point.lastUpdate, point.heading)) return;

    let update;
    const { mapEntities, tracks } = getState() as TState;
    const isIn = tracks.vectors.some(
      (v: TVesselTrackVector) => v.vesselId === vesselId
    );
    const vesselEntry = mapEntities.vessels.find(
      (v: TVesselLocationExt) => v.id === vesselId
    );

    const vector = createVector(
      vesselId,
      point,
      (vesselEntry as any as TVesselLocationExt)?.uiState?.color
    );

    if (isIn) {
      update = tracks.vectors.map((v: TVesselTrackVector) =>
        v.vesselId === vesselId ? vector : v
      );
    } else {
      update = [...tracks.vectors, vector];
    }

    dispatch({
      type: ETracksActions.CREATE_TRACK_VECTOR,
      payload: { vectors: update }
    });
  };
}

/**/
export function removeTrackVectorAction(vesselId: number) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    const vectors = tracks.vectors.filter(
      (v: TVesselTrackVector) => v.vesselId !== vesselId
    );

    dispatch({
      type: ETracksActions.REMOVE_TRACK_VECTOR,
      payload: { vectors }
    });
  };
}

/**/
export function updateTrackVectorsAction(locations: TVesselLocation[]) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;

    const vectors = locations
      .map((l: TVesselLocation): TVesselTrackVector => {
        if (!isPointValid(l.lastUpdate, l.heading)) return;

        const vesselEntry = mapEntities.vessels.find(
          (v: TVesselLocationExt) => v.id === l.id
        );

        return createVector(
          l.id,
          l,
          (vesselEntry as any as TVesselLocationExt)?.uiState?.color
        );
      })
      .filter(Boolean);

    dispatch({
      type: ETracksActions.UPDATE_TRACK_VECTORS,
      payload: { vectors }
    });
  };
}

/**
 *
 */
export function gotoHTPageAction() {
  return (dispatch) => {
    dispatch(push(ROUTES.HISTORICAL_TRACK));
    GA.reportTrackPeriod(ETrackPeriod.DAYS90 + '_PLUS');
  };
}
