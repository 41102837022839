import createAsyncActions from 'store/_utils/createAsyncActions';
import { TPlayerState } from './playerModel';

export enum EPlayerActions {
  ASSIGN_ENTITIES = 'player/ASSIGN_ENTITIES',
  FETCH_FARMS = 'player/FETCH_FARMS',
  FETCH_LOCATION = 'player/FETCH_LOCATION',
  FETCH_TRACK = 'player/FETCH_TRACK'
}

export const fetchFarmsSet = createAsyncActions<TPlayerState>(
  EPlayerActions.FETCH_FARMS
);

export const fetchLocationSet = createAsyncActions<TPlayerState>(
  EPlayerActions.FETCH_LOCATION
);

export const fetchTrackSet = createAsyncActions<TPlayerState>(
  EPlayerActions.FETCH_TRACK
);
