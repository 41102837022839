import PropTypes from 'prop-types';
import { PTCountryISO, TCountryISO } from './dictionaries';
import { TIdLocationDict } from './general';

export type TOwner = {
  address: string;
  city: string;
  companyId: number;
  country: TCountryISO;
  end: string;
  groupCompanyId?: number;
  id: number;
  name: string;
  postcode: string;
  registrationNumber: string;
  serviceProviderId: number;
  start: string;
  statusId?: number;
};

export type TOwnerShort = {
  address: string;
  city: string;
  country: string;
  id: 1874;
  name: string;
  postcode: string;
  registrationNumber: string;
};

export const PTOwner = {
  address: PropTypes.string,
  city: PropTypes.string,
  companyId: PropTypes.number,
  country: PropTypes.exact(PTCountryISO),
  end: PropTypes.string,
  groupId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  postcode: PropTypes.string,
  registrationNumber: PropTypes.string,
  serviceProviderId: PropTypes.number,
  start: PropTypes.string,
  statusId: PropTypes.number
};

export const PTOwnerShort = {
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  postcode: PropTypes.string,
  registrationNumber: PropTypes.string
};

// todo
export type TFarmHolder = {
  id: number;
  registrationNumber: string;
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: TCountryISO;
  labels: null;
  status: TIdLocationDict;
  user: null;
  vessels: null;
  group: [];
  groupCompanies: [];
};
