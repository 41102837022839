import { PTCompany, PTCompanyAF, TCompany, TCompanyAF } from '../company';
import PropTypes from 'prop-types';

export type TCrmCompany = TCompany & {
  key: string;
};

export type TCrmCompanyAF = TCompanyAF & {
  key: string;
};

export const PTCrmCompany = {
  ...PTCompany,
  key: PropTypes.string
};

// TODO: need to unite TCrmCompanyAF and TCrmCompany
export const PTCrmCompanyAF = {
  ...PTCompanyAF,
  key: PropTypes.string
};
