import { basicInitialState } from 'store/_utils/basicInitialState';
import { SettingsService } from 'services/settings';

import { TProvidersFilter } from 'types';
import { TProvidersState } from './providersModel';

export const providersFilterInitialSettings: TProvidersFilter = {
  isListFilterSet: false,
  isMapFilterSet: false,
  listFilterCategories: [],
  listFilterCountries: [],
  mapFilterCategories: null
};
const settings = SettingsService.readSettings();
const filterSettings: TProvidersFilter =
  settings[SettingsService.PROVIDERS_FILTER] || providersFilterInitialSettings;

export const providersInitialState: TProvidersState = {
  ...basicInitialState,
  addressArray: [],
  categories: [],
  filterSettings: filterSettings,
  providers: []
};
