import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './Logo.scss';
import { ENV, VERSION } from 'other/config';
import { getSubdomain } from 'other/helpers';
import { isApp } from '../../../helpers/helpers';
import { ROUTES } from 'other/constants';
import { SettingsService } from 'services/settings';
import { withAuth } from '../../../withAuth';
import { EUserAuthority } from 'types';

/**/
type Props = {
  altName?: string;
  className?: string;
  homeRoute?: string;
  imageUrl?: string;
  rights: ReadonlyArray<EUserAuthority>;
};

/**
 *
 */
const Component: FunctionComponent<Props> = ({
  altName,
  className,
  homeRoute,
  imageUrl,
  rights
}) => {
  const logoClassName = classnames('Logo', className);
  const buildInfo = SettingsService.readSettings()[SettingsService.BUILD_INFO];
  const version = buildInfo?.buildHash
    ? VERSION + `.${buildInfo.buildHash}`
    : VERSION;

  return (
    <Link className={logoClassName} to={homeRoute}>
      <img className="Logo__image" src={imageUrl} alt={altName} />

      {rights.includes(EUserAuthority.DEVELOPER) && (
        <div className="Logo__version">
          {version}
          {isApp() && <span> — {getSubdomain()}</span>}
        </div>
      )}
    </Link>
  );
};

/**
 *
 */
Component.propTypes = {
  altName: PropTypes.string,
  homeRoute: PropTypes.string,
  imageUrl: PropTypes.string
};

Component.defaultProps = {
  altName: ENV.APP_NAME,
  homeRoute: ROUTES.HOME,
  imageUrl: ENV.HEADER_LOGO
};

/**/
const Logo = withAuth(Component as any);
export { Logo };
