import platform from 'platform';
import ReactGA from 'react-ga4';
import ReactLinkedInTag from 'react-linkedin-insight';
import ReactPixel from 'react-facebook-pixel';

import { ANALYTICS, ENV } from 'other/config';
import { history } from '../store/store';
import { isApp, isMobile, isTablet } from '../components/helpers/helpers';
import { EBannerType, EMapLayer, ETrackMode, ETrackPeriod } from '../types';

// Initializes Facebook Pixel
export function initFBP(): void {
  ReactPixel.init(ANALYTICS.FACEBOOK_PIXEL, null, {
    autoConfig: true,
    debug: false
  });
  ReactPixel.pageView();
}

// Initializes Google Analytics
export function initGA(): void {
  ReactGA.initialize(ENV.GA_MEASUREMENT_ID);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search
  });

  history.listen(({ pathname }) => {
    ReactGA.set({ pathname });
    ReactGA.send('pageview');
  });
}

// Initializes Linkedin Insight
export function initLIT(): void {
  // @ts-ignore
  ReactLinkedInTag.init(ANALYTICS.LINKEDIN_INSIGHT, 'dc');
}

/**
 *
 */
export class GA {
  /**/
  public static reportBannerClick(id: number, type: EBannerType) {
    ReactGA.event({
      action: `Banner ${type} is clicked`,
      category: 'Banner',
      label: JSON.stringify({ id, type })
    });
  }

  /**/
  public static reportBannerImpression(id: number, type: EBannerType) {
    ReactGA.event({
      action: 'Banner IMPRESSION',
      category: 'News',
      label: JSON.stringify({ id, type })
    });
  }

  /**/
  public static reportCreateArticle() {
    ReactGA.event(ANALYTICS.EVENT_NAME.CREATE_ARTICLE_CLICK, {
      category: ANALYTICS.CATEGORY.PRODUCT_ACTION,
      component: ANALYTICS.COMPONENT.NEWS
    });
  }

  /**/
  public static reportCreateCampaign() {
    ReactGA.event(ANALYTICS.EVENT_NAME.CREATE_CAMPAIGN_CLICK, {
      category: ANALYTICS.CATEGORY.PRODUCT_ACTION,
      component: ANALYTICS.COMPONENT.NEWS
    });
  }

  /**/
  public static reportDarkMode() {
    ReactGA.event(ANALYTICS.EVENT_NAME.DARK_MODE_ON, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE
    });
  }

  /**/
  public static reportDistTool() {
    ReactGA.event(ANALYTICS.EVENT_NAME.DISTANCE_TOOL_OPENED, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportFullscreen() {
    ReactGA.event(ANALYTICS.EVENT_NAME.FULL_SCREEN_ON, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportLayerChange(layer: EMapLayer) {
    ReactGA.event(ANALYTICS.EVENT_NAME.LAYER_CHANGE, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE,
      component: ANALYTICS.COMPONENT.MAP,
      label: JSON.stringify({ layer })
    });
  }

  /**/
  public static reportLayerSelectorShown() {
    ReactGA.event(ANALYTICS.EVENT_NAME.LAYER_SELECTOR_OPENED, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportLegendShown() {
    ReactGA.event(ANALYTICS.EVENT_NAME.MAP_LEGEND_OPENED, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportLocationRequest() {
    ReactGA.event(ANALYTICS.EVENT_NAME.MY_LOCATION_CLICK, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportMapFilterShown() {
    ReactGA.event(ANALYTICS.EVENT_NAME.MAP_FILTER_OPENED, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP
    });
  }

  /**/
  public static reportFBShared(category: string, id: number) {
    ReactGA.event({
      action: `Facebook Share ${category} is clicked`,
      category: 'Facebook Share',
      label: JSON.stringify({ id: id, type: category })
    });
  }

  /**/
  public static reportLinkedInShared(category: string, id: number) {
    ReactGA.event({
      action: `Linked In ${category} is clicked`,
      category: 'Linked In Share',
      label: JSON.stringify({ id: id, type: category })
    });
  }

  /**/
  public static reportLogin(userId: number) {
    ReactGA.event({
      action: 'Login',
      category: 'User',
      label: JSON.stringify({
        userId,
        userAgentName: platform.name,
        userAgentVersion: platform.version
      })
    });
  }

  /**/
  public static reportNewsImpression(id: number) {
    ReactGA.event({
      action: 'News IMPRESSION',
      category: 'News',
      label: String(id)
    });
  }

  /**/
  public static reportTrackAdd(
    trackCount: number,
    trackMode: ETrackMode,
    trackPeriod: ETrackPeriod
  ) {
    ReactGA.event(ANALYTICS.EVENT_NAME.VESSEL_TRACK_REQUEST, {
      category: ANALYTICS.CATEGORY.CONTROL_ACTIVATION,
      component: ANALYTICS.COMPONENT.MAP,
      label: JSON.stringify({ trackCount, trackMode, trackPeriod })
    });
  }

  /**/
  public static reportTrackMode(trackMode: ETrackMode | string) {
    ReactGA.event(ANALYTICS.EVENT_NAME.TRACK_MODE_CHANGE, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE,
      component: ANALYTICS.COMPONENT.MAP,
      label: JSON.stringify({ trackMode })
    });
  }

  /**/
  public static reportTrackPeriod(trackPeriod: ETrackPeriod | string) {
    ReactGA.event(ANALYTICS.EVENT_NAME.TRACK_PERIOD_CHANGE, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE,
      component: ANALYTICS.COMPONENT.MAP,
      label: JSON.stringify({ trackPeriod }),
      platform: GA.getPlatform()
    });
  }

  /**/
  public static reportTrialClicked() {
    ReactGA.event({
      action: 'Trial "Get Started" button is clicked',
      category: 'Trial',
      label: JSON.stringify({ location: window.location.pathname })
    });
  }

  /**/
  public static reportTrialShown() {
    ReactGA.event({
      action: 'Trial Modal is shown',
      category: 'Trial',
      label: JSON.stringify({ location: window.location.pathname })
    });
  }

  /**/
  public static reportHistoricalTrack(key: string, value: string) {
    ReactGA.event(ANALYTICS.EVENT_NAME.HISTORICAL_CATCH_OPTION_CHANGE, {
      category: ANALYTICS.CATEGORY.OPTION_CHANGE,
      component: ANALYTICS.COMPONENT.HISTORICAL_CATCH,
      label: JSON.stringify({ [key]: value })
    });
  }

  /**/
  private static getPlatform(): string {
    if (isApp()) return 'APP';
    if (isMobile()) return 'WEB.MOBILE';
    if (isTablet()) return 'WEB.TABLET';
    return 'WEB.DESKTOP';
  }
}
