import React, { ComponentType, FC } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginPage from 'pages/Login/LoginPage';

import { TState } from 'store/appStateModel';

type Props = {
  component: ComponentType;
};

/**
 * A Route guarding wall.
 * Redirects away on unauthorized attempts to enter a specific route.
 * @param props
 * @constructor
 */
const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(
    ({ session }: TState) => !!session?.user?.token
  );

  return <Route {...rest}>{isLoggedIn ? <Component /> : <LoginPage />}</Route>;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export { PrivateRoute };
