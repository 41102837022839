import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import './Search.scss';
import { hideKeyboard } from 'components/helpers/utils';
import { IconButton } from 'components/common/buttons/IconButton/IconButton';
import { LinkButton } from 'components/common/buttons/LinkButton/LinkButton';
import { Search as SearchComponent } from './Search/Search';
import {
  searchRequestAction,
  selectSearchResultAction
} from 'store/search/searchActions';

import { TSearchChoice, TSearchResultExt } from 'types';
import { TState } from 'store/appStateModel';

/**/
type Props = {
  className?: string;
  getPopupContainer?: () => HTMLElement;
  isPending: boolean;
  onSearch: (value: string) => void;
  onSelect: (value: TSearchChoice) => void;
  query: string;
  results: TSearchResultExt[];
};

type State = {
  isExpanded: boolean;
};

/**
 *
 */
class SearchInner extends PureComponent<Props, State> {
  state: State = {
    isExpanded: false
  };

  toggleSearch = () => this.setState({ isExpanded: !this.state.isExpanded });

  onSelect = (item: TSearchChoice) => {
    const { onSelect } = this.props;
    this.toggleSearch();
    hideKeyboard();
    onSelect(item);
  };

  render() {
    const {
      className,
      getPopupContainer,
      isPending,
      onSearch,
      query,
      results
    } = this.props;
    const { isExpanded } = this.state;
    const searchClassName = classNames('HeaderSearch', className);

    const searchInputWrapperClassName = classNames(
      'HeaderSearch__inputWrapper',
      {
        'HeaderSearch__inputWrapper--active': isExpanded
      }
    );

    return (
      <div className={searchClassName}>
        {isExpanded || (
          <IconButton
            className="HeaderSearch__icon"
            icon="search"
            onClick={this.toggleSearch}
            size="S"
          />
        )}

        <div className={searchInputWrapperClassName}>
          <SearchComponent
            className="HeaderSearch__input"
            isPending={isPending}
            query={query}
            results={results}
            {...(getPopupContainer && { getPopupContainer })}
            onSearch={onSearch}
            onSelect={this.onSelect}
          />

          <LinkButton
            className="HeaderSearch__closeButton"
            type="link"
            onClick={this.toggleSearch}
          >
            Close
          </LinkButton>
        </div>
      </div>
    );
  }
}

/**
 *
 */
const mapStateToProps = ({
  search: { isPending, searchQuery, searchResults }
}: TState) => ({
  isPending: isPending,
  query: searchQuery,
  results: searchResults
});

const mapDispatchToProps = {
  onSearch: searchRequestAction,
  onSelect: selectSearchResultAction
};

const Search = connect(mapStateToProps, mapDispatchToProps)(SearchInner);
export { Search };
