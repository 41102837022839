import createAsyncActions from 'store/_utils/createAsyncActions';
import { TDictionariesState } from './dictionariesModel';

enum EDictionariesActions {
  FETCH_DICTIONARIES = 'dictionaries/FETCH_DICTIONARIES'
}

export const fetchSet = createAsyncActions<TDictionariesState>(
  EDictionariesActions.FETCH_DICTIONARIES
);
