import PropTypes from 'prop-types';

import { EEntityType, PTTargetEntity, TTargetEntity } from './common';
import { TMedia } from '../media';
import { TCreatedBy, PTCreatedBy } from '../general';

export type TCrmContact = {
  company?: string;
  createdBy: TCreatedBy;
  customFields?: {
    customFields?: {
      [key: string]: string;
    }[];
    emails?: string[];
    hobby?: string[];
    notes?: string[];
    position?: string;
    phones?: string[];
    socials?: string[];
  };
  entityLinks?: Array<{
    crmContactId?: number;
    entityId: number;
    entityType: EEntityType;
  }>;
  firstName: string;
  id: number;
  lastName?: string;
  photo?: TMedia;
  targetEntities: TTargetEntity[];
  key: string;
};

export const PTCrmContact = PropTypes.exact({
  company: PropTypes.string,
  createdBy: PropTypes.exact(PTCreatedBy),
  customFields: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number,
  key: PropTypes.string,
  lastName: PropTypes.string,
  photo: PropTypes.exact({
    copyright: PropTypes.string,
    format: PropTypes.string,
    id: PropTypes.number,
    path: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  targetEntities: PropTypes.arrayOf(PropTypes.exact(PTTargetEntity))
});
