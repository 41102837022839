export type TDeckEquipment = {
  winches?: TWinch[];
  cranes?: TCrane[];
  fishPump?: TFishPump;
  fishHandling?: {
    details?: string;
    manufacturerId?: number;
  };
};

// Deck Machinery and Equipment
export type TWinch = {
  method: EWinchMethod;
  type: EWinchType;
  quantity: number;
  details: string;
  tonnage: number;
  manufacturerId: number;
};

export enum EWinchMethod {
  HYDRAULIC = 'Hydraulic',
  ELECTRIC = 'Electric'
}

export enum EWinchType {
  TRAWL = 'Trawl',
  NET_SOUNDER = 'Net Sounder',
  NET_DRUM = 'Net Drum',
  CAPSTAN = 'Capstan',
  AUX = 'Auxiliary',
  LONG_LINE = 'Longline',
  TOWING_MOORING = 'Towing and Mooring',
  PURSE_SEINE = 'Purse Seine',
  ANCHOR_WINCH = 'Anchor Winch',
  GILSON_WINCH = 'Gilson Winch',
  SWEEP_LINE = 'Sweep line',
  COD_END = 'Cod end',
  NET_HAULER = 'Net hauler',
  DANISH_SEINE = 'Danish Seine',
  WARPING = 'Warping winch',
  FLY_SHOOTING = 'Fly shooting winch',
  SERVICE_WINCH = 'Service winch',
  SPLIT_WINCH = 'Split winch',
  DOUBLE_DRUM = 'Double drum',
  DECK_MACHINERY = 'Deck machinery',
  TUGGER_WINCH = 'Tugger winch',
  OTHER = 'Other'
}

export type TCrane = {
  type: ECraneType;
  quantity: number;
  power: number;
  details: string;
  manufacturerId: number;
};

export enum ECraneType {
  DECK = 'Deck',
  NET = 'Net',
  CORK = 'Cork Line Stacker',
  SINK = 'Sink Line Stacker',
  KNUCKLEBOOM = 'Knuckleboom',
  PROVISION = 'Provision',
  TELESCOPE = 'Telescope'
}

export type TFishPump = {
  type: EFishPump;
  manufacturerId: number;
};

export enum EFishPump {
  VACUUM = 'Vacuum',
  HYDRAULIC = 'Hydraulic',
  PRESSURE = 'Pressure',
  ELECTRICAL = 'Electrical'
}
