import createAsyncActions from 'store/_utils/createAsyncActions';
import { TFarmsLocationsState } from './farmsLocationsModel';

export enum EFarmsLocationsActions {
  FETCH_LOCATIONS = 'farmsLocations/FETCH_LOCATIONS',
  START_WATCHING_LOCATIONS = 'farmsLocations/START_WATCHING_LOCATIONS',
  STOP_WATCHING_LOCATIONS = 'farmsLocations/STOP_WATCHING_LOCATIONS'
}

export const locationsSet = createAsyncActions<TFarmsLocationsState>(
  EFarmsLocationsActions.FETCH_LOCATIONS
);
