export const commonVariables = {
  token: {
    borderRadius: 4,
    borderRadiusLG: 4,
    borderRadiusSM: 4,
    fontFamily: 'Inter, Arial, sans-serif'
  },
  components: {
    Button: {
      colorText: '#4E5B6A',
      colorTextDisabled: '#B4B4B4'
      // primaryColor: '#FFF'
    },
    // Checkbox: {
    //   colorWhite: 'white',
    // },
    // FormItem: {
    //   // marginBottom: '24px;'
    // },
    Collapse: {
      collapseHeaderPadding: '22px 40px 24px 0'
    },
    DatePicker: {
      colorBgContainer: 'transparent',
      colorTextPlaceholder: '#979797'
    },
    Drawer: {
      zIndexPopup: 1100
    },
    Input: {
      // colorBgContainer: '#23273a', // (--body-background)
      // colorBorder: '#4e5b6a', // --button-default-border
      colorBgContainerDisabled: '#f5f5f5',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25);',
      colorTextPlaceholder: '#979797', // --input-placeholder-color
      controlOutline: void 0, // (--primary-color-light)
      fontSize: '16px',
      fontSizeIcon: '16px'
      // inputPaddingHorizontal: '11px',
      // inputPaddingVertical: '3.9px',
      // inputBorderActiveColor: '#ff8b6b', // (--primary-color-light)
      // inputBorderHoverColor: '#ff8b6b', // (--primary-color-light)
    },
    Modal: {
      zIndexPopupBase: 1102
    },
    Popover: {
      popoverPadding: 0
    },
    Select: {},
    Slider: {
      colorFillSecondary: '#e7e7e6',
      colorFillTertiary: '#e7e7e6',
      fontSize: '12px'
      // handleSize: '8px',
      // railSize: '2px'
    },
    Tabs: {
      boxShadow:
        '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)'
    },
    Tag: {
      tagDefaultBg: 'transparent'
    },
    Tooltip: {
      tooltipColor: '#fff'
    }
  }
};
