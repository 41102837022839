import { basicInitialState } from '../_utils/basicInitialState';
import { TNotificationsState } from './notificationsModel';

export const notificationsInitialState: TNotificationsState = {
  ...basicInitialState,
  notifications: null,
  pagination: {
    page: 0,
    size: 12,
    total: 0
  },
  totalUnread: 0
};
