import React from 'react';
import Drawer from 'antd/lib/drawer';

import './Aside.scss';
import './BurgerAside.scss';

import { BannerCarousel } from 'components/common/carousels/BannerCarousel';
import { Navigation } from './Navigation/Navigation';
import ThemeToggle from './ThemeToggle/ThemeToggle';
import { TrialLink } from 'components/common/TrialLink/TrialLink';
import { EBannerType, TNavigationItem } from 'types';

/**/
type Props = {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  items: TNavigationItem[];
  toggleAside: () => void;
};

/**/
function getContainer() {
  return document.getElementById('app');
}

/**
 *
 */
function BurgerAside({ isAsideOpen, isLoggedIn, items, toggleAside }: Props) {
  return (
    <Drawer
      className="BurgerAside"
      closable={false}
      getContainer={getContainer}
      destroyOnClose={true}
      open={isAsideOpen}
      onClose={toggleAside}
      placement="left"
      rootClassName="BurgerAside__root"
    >
      <div className="BurgerAside__holder">
        {isLoggedIn ? (
          <div className="Aside__banner">
            <BannerCarousel type={EBannerType.MENU} onItemClick={toggleAside} />
          </div>
        ) : (
          <TrialLink isPrimary={true} />
        )}

        <Navigation
          closeAside={toggleAside}
          isAsideOpen={true}
          isLoggedIn={isLoggedIn}
          items={items}
        />
      </div>

      <ThemeToggle />
    </Drawer>
  );
}

/**
 *
 */
export { BurgerAside };
