import PropTypes from 'prop-types';

import { TMedia } from './media';
import { TSelectOption } from 'types';

export enum EBannerType {
  MENU = 'MENU',
  NEWS = 'NEWS',
  FILTERS = 'FILTERS',
  MOBILE = 'MOBILE'
}

export enum ECampaignFormStep {
  UPLOAD = 0,
  FORM,
  RECAP
}
export type TBannerStatus = {
  data?: TMedia;
  uploadProgress: number;
};

export enum ECampaignTarget {
  SERVICE = 'Service provider',
  PRODUCT = 'Product',
  ARTICLE = 'News article'
}

export type TCampaignTargetGroup = {
  type: ECampaignTarget;
  items: TSelectOption<string>[];
};

export type TBannerSlide = {
  campaignId: number;
  imagePath: string;
  targetPath: string;
};

export const PTBannerSlide = {
  campaignId: PropTypes.number,
  imagePath: PropTypes.string,
  targetPath: PropTypes.string
};

export type TBannerSet = {
  [EBannerType.MENU]: TBannerSlide[];
  [EBannerType.NEWS]: TBannerSlide[];
  [EBannerType.FILTERS]: TBannerSlide[];
  [EBannerType.MOBILE]: TBannerSlide[];
};

export const PTBannerSet = {
  [EBannerType.MENU]: PropTypes.arrayOf(PropTypes.exact(PTBannerSlide)),
  [EBannerType.NEWS]: PropTypes.arrayOf(PropTypes.exact(PTBannerSlide)),
  [EBannerType.FILTERS]: PropTypes.arrayOf(PropTypes.exact(PTBannerSlide)),
  [EBannerType.MOBILE]: PropTypes.arrayOf(PropTypes.exact(PTBannerSlide))
};
