import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { IconCustom } from '../../../common/IconCustom/IconCustom';
import { TNotification } from 'types';

/**
 *
 */
abstract class AbstractNotificationContent<T> {
  protected body: T;
  protected abstract defaultIcon: string;
  protected notification: TNotification;

  /**/
  constructor(notification: TNotification) {
    this.notification = notification;
    this.parseBody();
  }

  /**/
  protected parseBody(): void {
    this.body = JSON.parse(this.notification.body) as T;
  }

  /**/
  public abstract getContent(): ReactElement;

  /**/
  protected getDefaultIcon(): ReactElement {
    return (
      <IconCustom
        className="NotificationListItem__icon"
        title="Open link"
        type={this.defaultIcon}
      />
    );
  }

  /**/
  public abstract getIcon(): ReactElement;

  /**/
  public abstract getPath(): string;

  /**/
  public getDate(): string {
    const { createdAt } = this.notification;
    if (!createdAt || typeof createdAt !== 'string') return '';

    const offset = new Date().getTimezoneOffset();
    const d = dayjs(createdAt).add(-offset, 'minutes');
    const now = dayjs();

    if (d.isToday()) {
      const hours = now.diff(d, 'hours');
      if (hours >= 1) {
        return Math.floor(hours) + 'h ago';
      }

      const min = now.diff(d, 'minutes');
      return Math.floor(min) + 'min ago';
      /**/
    } else if (d.isYesterday()) {
      const time = d.format('HH:mm');
      return 'Yesterday, ' + time;
    }

    return `${d.format('D MMM YYYY')}, ${d.format('HH:mm')}`;
  }
}

/**/
export default AbstractNotificationContent;
