import React, { ReactElement } from 'react';

import AbstractNotificationContent from './AbstractNotificationContent';
import { PROVIDER_IMAGE_URL } from 'other/config';
import { ROUTES } from 'other/constants';

import { EMentionSubject, TMentionNotification } from './helpers';

/**
 *
 */
class MentionNotificationObject extends AbstractNotificationContent<TMentionNotification> {
  protected defaultIcon = 'at-sign';

  /**/
  public getContent(): ReactElement {
    try {
      const { taggedByName, taggedEntityName, taggedInName } = this.body;
      const text = ` in the ${this.getSubject()} `;

      return (
        <span>
          <span>
            <b>{taggedByName}</b> mentioned <b>{taggedEntityName}</b>
            {text}
          </span>
          <span className="NotificationListItem__link">{taggedInName}</span>
        </span>
      );
    } catch (e) {
      window.console.error(e);
      return <span>Something went wrong.</span>;
    }
  }

  /**/
  private getSubject(): string {
    switch (this.body.taggedInType) {
      case EMentionSubject.EVENT:
        return 'event';
      case EMentionSubject.NEWS:
        return 'article';
      case EMentionSubject.PRODUCT:
        return 'product page';
      default:
        throw new TypeError(
          `Unknown mention subject type: ${this.body.taggedInType}`
        );
    }
  }

  /**/
  public getIcon(): ReactElement {
    const { taggedByLogo } = this.body;
    if (!taggedByLogo) return this.getDefaultIcon();

    const src = PROVIDER_IMAGE_URL + '/' + taggedByLogo;
    return <img src={src} alt="Logo" className="NotificationListItem__logo" />;
  }

  /**/
  public getPath(): string {
    const { taggedById, taggedInId, taggedInType } = this.body;

    switch (taggedInType) {
      case EMentionSubject.EVENT:
        return ROUTES.EVENTS + '/' + taggedInId;
      case EMentionSubject.NEWS:
        return ROUTES.NEWS + '/' + taggedInId;
      case EMentionSubject.PRODUCT:
        return (
          ROUTES.SERVICE_PROVIDER + '/' + taggedById + '/products/' + taggedInId
        );
      default:
        throw new TypeError(`Unknown mention subject type: ${taggedInType}`);
    }
  }
}

/**/
export default MentionNotificationObject;
