import { LAYER_DEPTHS_MAP } from './layerValues';
import { EMapLayer } from 'types';

/**
 *
 */
export function getElevation(depth: number, layer: EMapLayer): string {
  const depthValue =
    typeof depth === 'number' && depth > 0
      ? depth
      : getNearestDepthValue(depth, layer);

  const low = Math.floor(depthValue);
  const high = Math.ceil(depthValue);
  return low + '/' + (low === high ? high + 1 : high);
}

/**
 *
 */
export function getNearestDepthValue(depth: number, layer: EMapLayer): number {
  return LAYER_DEPTHS_MAP[layer].reduce((prev, curr) =>
    Math.abs(curr - depth) < Math.abs(prev - depth) ? curr : prev
  );
}
