import React, { CSSProperties, MouseEvent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './IconCustom.scss';

type Props = {
  className?: string;
  /** an HTML attribute. */
  style?: CSSProperties;
  /** an icon name. */
  title?: string;
  /** a style object. */
  type: string;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
};

/**
 * Renders custom icons, defined with <i>Icomoon</i>.
 * To see all the available icons,
 * run <code>./dev-assets/icomoon/demo.html </code>
 * @param className
 * @param title
 * @param type
 * @param style
 * @param onClick
 * @constructor
 */
const IconCustom: React.FC<Props> = ({
  className,
  title,
  type,
  style,
  onClick
}): ReactElement => {
  const cls = classnames(`icon-custom icon-${type}`, className);
  return <span className={cls} style={style} title={title} onClick={onClick} />;
};

IconCustom.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};
export { IconCustom };
