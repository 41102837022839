import { defaultFilterRanges } from '../dictionaries/dictionariesInitialState';
import { SettingsService } from 'services/settings';
import { TFiltersModel } from './filtersModel';

export const filtersBlankState: TFiltersModel = {
  buildYearValue: defaultFilterRanges.yearRange,
  companyValue: [],
  countryValue: [],
  engineModelValue: [],
  enginePowerValue: defaultFilterRanges.powerRange,
  facilityValue: [],
  fleetValue: [],
  lengthValue: defaultFilterRanges.lengthRange,
  specieValue: [],
  tonnageValue: defaultFilterRanges.tonnageRange,
  vesselTypeValue: [],

  isFarmFilterSet: false,
  isVesselFilterSet: false
};

const settings: TFiltersModel =
  SettingsService.readSettings()[SettingsService.MAIN_FILTER];

export const filtersInitialState: TFiltersModel = {
  ...filtersBlankState,
  ...settings
};
