import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './LoaderOverlay.scss';
import { Loading } from 'components/common/Loading/Loading';

type Props = {
  /** a CSS class name. */
  className?: string;
  /** React children or a child. */
  children?: ReactElement | ReactElement[];
  /** a pending state flag. */
  isLoading?: boolean;
  /** whether to render the children while pending. */
  isTransparent?: boolean;
  /** a map of HTML attributes passed to the <code>DIV</code> container. */
  layoutProps?: {};
};

/**
 * A spinner covered overlay.
 * Optionally renders its content while <code>isLoading === true</code>.
 * @param className
 * @param children
 * @param isLoading
 * @param isTransparent
 * @param layoutProps
 * @constructor
 */
const LoaderOverlay: React.FC<Props> = ({
  className,
  children,
  isLoading,
  isTransparent,
  layoutProps
}): ReactElement => {
  const cls = classnames('LoaderOverlay', className);
  const isContentShown = isLoading ? isTransparent : true;

  return (
    <div className={cls} {...layoutProps}>
      {isLoading && <Loading className="LoaderOverlay__spinner" />}
      {isContentShown && children}
    </div>
  );
};

LoaderOverlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  isLoading: PropTypes.bool,
  isTransparent: PropTypes.bool,
  layoutProps: PropTypes.object
};

export { LoaderOverlay };
