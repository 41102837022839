import { TAction } from './reducerCreator';

export const okAction = <S>(
  type: string,
  data: Partial<S> = {}
): TAction<S, string> => ({
  type: type,
  payload: {
    error: null,
    ...data
  }
});

export const errorAction = <S>(
  type: string,
  error: Error,
  data: Partial<S> = {}
): TAction<S, string> => {
  window.console.error(`${type}\n${error}`);

  return {
    type: type,
    payload: {
      error: error.message,
      ...data
    }
  };
};
