import { basicInitialState } from '../_utils/basicInitialState';
import { SettingsService } from 'services/settings';
import { TSessionState } from './sessionModel';

const isConnectionPoor =
  SettingsService.readSettings()[SettingsService.IS_SLOW_INTERNET];

export const sessionInitialState: TSessionState = {
  ...basicInitialState,
  isConnectionPoor: isConnectionPoor,
  redirectPath: null,
  user: null
};
