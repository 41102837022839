import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

import './CookieConsentCustom.scss';
import { getToken } from 'services/auth';
import { APP_CONTENT } from 'other/constants';
import { FF_CONSENT } from 'other/config';
import { isCordova } from 'other/helpers';
import { ROUTES } from 'other/constants';
import { startTracking } from 'services/tracker';

/**/
const props = {
  buttonClasses: 'ant-btn ant-btn-primary ant-btn-block ant-btn-lg',
  buttonText: 'Accept',
  cookieName: FF_CONSENT,
  disableButtonStyles: true,
  disableStyles: true,
  onAccept: () => setTimeout(() => startTracking()),
  style: { background: '#2B373B' }
};

/**
 *
 */
class CookieConsentCustom extends React.PureComponent {
  /**/
  private elem: HTMLDivElement;
  private ro: ResizeObserver;

  /**/
  componentDidMount() {
    if (isCordova() || getToken()) return;
    const parent = document.getElementById(APP_CONTENT);

    if (parent) {
      this.ro = new ResizeObserver(this.handleResize);
      this.ro.observe(parent);
    }
  }

  /**/
  handleResize = (entries) => {
    for (const entry of entries) {
      this.elem.style.left = entry.target.offsetLeft + 'px';
    }
  };

  /**/
  makeRef = (el) => (this.elem = el);

  /**/
  componentWillUnmount() {
    const parent = document.getElementById(APP_CONTENT);
    parent && this.ro?.unobserve(parent);
  }

  /**/
  render() {
    if (isCordova() || getToken()) return null;

    return (
      <div className="CookieConsentCustom" ref={this.makeRef}>
        <CookieConsent {...props}>
          <p className="CookieConsent__text">
            We use cookies to offer you the best experience. By clicking
            “Accept” you consent to store all the technologies described in our{' '}
            <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>.
          </p>
        </CookieConsent>
      </div>
    );
  }
}

/**/
export { CookieConsentCustom };
