import React from 'react';
import classnames from 'classnames';
import './LoadingSimple.scss';

type Props = {
  className?: string;
  text?: string;
};
type State = {
  count: number;
};

/**/
class LoadingSimple extends React.PureComponent<Props, State> {
  static defaultProps = { text: 'Loading' };
  private static INTERVAL = 300;
  private static SYMBOLS = ['', '.', '..', '...'];
  private timer;

  state: State = {
    count: 0
  };

  componentDidMount() {
    this.timer = setInterval(
      () => this.setState({ count: this.state.count + 1 }),
      LoadingSimple.INTERVAL
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { className, text } = this.props;
    const cls = classnames('LoadingSimple', className);
    const symbol = LoadingSimple.SYMBOLS[this.state.count % 4];

    return (
      <span className={cls}>
        <span className="LoadingSimple__text">{text}</span>
        <big className="LoadingSimple__spin">{symbol}</big>
      </span>
    );
  }
}

/**/
const loader = <LoadingSimple className="app-ml-2 app-pt-2" />;

export { LoadingSimple, loader };
