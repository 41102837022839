import { basicInitialState } from '../../_utils/basicInitialState';
import { TNewsRecentState } from './newsRecentModel';

export const newsRecentInitialState: TNewsRecentState = {
  ...basicInitialState,
  news: [],
  page: 0,
  size: 6,
  total: 0,
  scrollTop: 0
};
