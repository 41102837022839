import React from 'react';

import './LocationFailMessage.scss';
import { PrimaryButton } from '../buttons/PrimaryButton/PrimaryButton';

/**
 *
 */
function getContent(onRetry) {
  return (
    <span className="LocationFailMessage">
      Connection Error.
      <PrimaryButton
        className="LocationFailMessage__button"
        onClick={onRetry}
        size="XS"
      >
        Refresh
      </PrimaryButton>
    </span>
  );
}

/**
 *
 */
function getLocationFailMessage(onRetry: () => void) {
  return {
    className: 'LocationFailMessage',
    content: getContent(onRetry),
    duration: 0
  };
}

/**/
export { getLocationFailMessage };
