import createAsyncActions from 'store/_utils/createAsyncActions';
import { TSessionState } from './sessionModel';

export enum ESessionActions {
  CHECK_SESSION = 'session/CHECK_SESSION',
  INVALIDATE_SESSION = 'session/INVALIDATE_SESSION',
  UPDATE_SESSION = 'session/UPDATE_SESSION',
  UPDATE_SESSION_REQUEST = 'session/UPDATE_SESSION_REQUEST',

  STORE_SESSION = 'session/STORE_SESSION',
  STORE_REDIRECT_PATH = 'session/STORE_REDIRECT_PATH',
  CLEAR_REDIRECT_PATH = 'session/CLEAR_REDIRECT_PATH',

  TOGGLE_INTERNET_MODE = 'session/TOGGLE_INTERNET_MODE'
}

export const checkSessionSet = createAsyncActions<TSessionState>(
  ESessionActions.CHECK_SESSION
);
export const updateSessionSet = createAsyncActions<TSessionState>(
  ESessionActions.CHECK_SESSION
);
