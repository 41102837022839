import React, { ReactElement } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import { isFalsy, TItemProps } from 'other/helpers';
import { TFormField, TFormFields, TSelectOption } from 'types';

/**
 * Returns a dictionary of the required fields of FieldData type.
 * If field doesn't match it creates no output key.
 */
export function pickFormFields(
  fieldName: string | string[],
  fields: TFormField[]
): {
  [key: string]: TFormField;
} {
  const dict = {};
  const names = Array.isArray(fieldName) ? fieldName : [fieldName];

  fields.forEach((_field: TFormField) =>
    names.forEach((_name: string) => {
      if (_field.name.includes(_name)) {
        dict[_name] = _field;
      }
    })
  );
  return dict;
}

export const formItemProps = {
  colon: false,
  validateTrigger: 'onBlur'
};
export const commonFormProps = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  hideRequiredMark: true
};

/** Converts an array of antd4's form fields into a dictionary */
export function formFields2Dict(fields: TFormField[]): {
  [key: string]: TFormField;
} {
  const dict = {};

  fields.forEach((_field: TFormField) => (dict[_field.name[0]] = _field));
  return dict;
}

/** Plucks given fields from the object and converts them into antd's form fields */
export function buildFields(fieldNames: string[], obj: {}): TFormFields {
  const fields = {};
  fieldNames.forEach((field: string) => {
    fields[field] = {
      name: [field],
      value: obj[field]
    };
  });

  return fields;
}

/** Builds a form field. */
export function bf2(
  arr: Array<{
    [fieldName: string]: number | string | string[] | boolean | Dayjs | Dayjs[];
  }>
): TFormFields {
  const fields = {};
  arr.forEach((obj) => {
    const [fieldName, value] = Object.entries(obj)[0];
    fields[fieldName] = {
      name: [fieldName],
      value: isFalsy(value) ? void 0 : value
    };
  });

  return fields;
}

/** Converts antd form fields into a dictionary */
export function pluckFromFields(fields: TFormFields): {
  [key: string]: any;
} {
  const result = {};
  Object.values(fields).forEach(
    ({ name, value }: TFormField) => (result[name[0]] = value)
  );
  return result;
}

export function itemProps2inputs(props: TItemProps): ReactElement {
  return (
    <Form.Item key={props.name} {...props}>
      <Input />
    </Form.Item>
  );
}

/**
 * Used as a type-and-search filter callback for antd's Select.
 */
export function typeSearchFilter(
  input: string,
  option: TSelectOption<string>
): boolean {
  const index = option.label.toLowerCase().indexOf(input.toLowerCase());
  return index >= 0;
}
/**
 * A callback involved by antd Select to filter select options each time user hits keyboard.
 */
export function filterTreeNode(inputValue: string, treeNode): boolean {
  return treeNode.props.title
    .toString()
    .toLowerCase()
    .includes(inputValue.toLowerCase());
}

/**
 * Converts string date representation into Dayjs instance. Disregards time.
 */
export function stringDate2moment(str: string): Dayjs | void {
  if (!str) return;
  return dayjs(str.substring(0, 10));
}

/**
 * Changes date-time as if the user currently were placed in UTC timezone.
 */
export function pretendUTC(dt: Dayjs): string {
  const offset: number = new Date().getTimezoneOffset();
  return dt.clone().add(-offset, 'minutes').format('YYYY-MM-DD');
}

/**/
export function updateFieldByName(
  fields: TFormFields,
  name: string,
  value: any
) {
  const update = {
    ...(fields[name]
      ? fields[name]
      : {
          name: [name]
        }),
    value: value
  };

  if (!('name' in update)) {
    update.name = [name];
  }

  return {
    ...fields,
    [name]: update
  };
}
