import createAsyncActions from 'store/_utils/createAsyncActions';
import { TSearchState } from './searchModel';

export enum ESearchActions {
  SEARCH_TYPED = 'search/SEARCH_TYPED',
  SEARCH = 'search/SEARCH'
}

export const searchSet = createAsyncActions<TSearchState>(
  ESearchActions.SEARCH
);
