import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'antd/lib/auto-complete';
import classnames from 'classnames';
import Input from 'antd/lib/input';

import './Search.scss';
import { dropdownRender, getSearchOptions } from './helpers';
import { IconCustom } from 'components/common/IconCustom/IconCustom';
import { isTouchDevice, useWindowSize } from 'other/helpers';
import { queryString } from 'other/queryString/queryString';
import { TSearchChoice, TSearchResultExt } from 'types';

type Props = {
  /** the container CSS class. */
  className: string;
  /**
   * Parent Node which the selector should be rendered to.
   * Default to body. When position issues happen,
   * try to modify it into scrollable content and position it relative.
   */
  getPopupContainer?: () => HTMLElement;
  /** a pending flag. */
  isPending: boolean;
  /** on type event callback function. */
  onSearch: (value: string) => void;
  /** on result select event callback function. */
  onSelect: (value: TSearchChoice) => void;
  /** typed text. */
  query: string;
  /** a list of search results. */
  results: TSearchResultExt[];
};

// Todo: rework styles
export const Search: React.FC<Props> = ({
  className,
  getPopupContainer,
  isPending,
  onSearch,
  onSelect,
  query,
  results
}: Props): ReactElement => {
  const shouldAutofocus = !isTouchDevice();
  const options = getSearchOptions(results, query, isPending) as any;
  const cls: string = classnames('Search', className);

  const icon = isPending ? (
    <IconCustom className="Search__icon" type="loading" />
  ) : (
    <IconCustom className="Search__icon" type="search" />
  );

  const handleSelect = (value: string): void =>
    onSelect(queryString.parse(value) as TSearchChoice);

  const [width] = useWindowSize();
  const render =
    width < 480
      ? dropdownRender(options, query, isPending, handleSelect)
      : void 0;

  return (
    <div className={cls}>
      <AutoComplete
        autoFocus={shouldAutofocus}
        dropdownRender={render}
        onSearch={onSearch}
        onSelect={handleSelect}
        options={options}
        popupClassName="Search__dropdown"
        popupMatchSelectWidth={true}
        value={query}
        {...(getPopupContainer && { getPopupContainer })}
      >
        <Input placeholder="Search..." suffix={icon} />
      </AutoComplete>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  getPopupContainer: PropTypes.func,
  isPending: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  query: PropTypes.string,
  results: PropTypes.array
};
