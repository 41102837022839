import { defaultFilterRanges } from './dictionariesInitialState';
import { LOCALE } from 'other/config';
import { stringComparator } from 'other/helpers';

import {
  TCountryISO,
  TFilterRangeDict,
  TIdLocationDict,
  TLanguageISO,
  TMinMax,
  TSelectOption
} from 'types';

/** Sorts them alphabetically */
export function sortCountries(countries: TCountryISO[]): TCountryISO[] {
  const locale: string = LOCALE;

  (countries || []).sort((a: TCountryISO, b: TCountryISO): -1 | 0 | 1 => {
    if (a.value[locale] < b.value[locale]) return -1;
    if (a.value[locale] > b.value[locale]) return 1;
    return 0;
  });

  return countries;
}

/** Converts ISO type into label-value */
export function mapISOCountries(
  countries: TCountryISO[]
): TSelectOption<string>[] {
  const locale: string = LOCALE;

  return (countries || []).map(
    (c: TCountryISO): TSelectOption<string> => ({
      label: c.value[locale],
      value: c.iso
    })
  );
}

/**
 * Converts languages representation and sorts them alphabetically.
 */
export function handleLanguages(
  languages: TLanguageISO[]
): TSelectOption<string>[] {
  const locale: string = LOCALE;

  return (languages || [])
    .map(
      (l: TLanguageISO): TSelectOption<string> => ({
        label: l.value[locale],
        value: l.iso
      })
    )
    .sort((a: TSelectOption<string>, b: TSelectOption<string>): -1 | 0 | 1 => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
}

export function handleSpecies(
  species: TIdLocationDict[]
): TSelectOption<number>[] {
  return (species || [])
    .map(
      ({ id, value }: TIdLocationDict): TSelectOption<number> => ({
        label: value.en_GB,
        value: id
      })
    )
    .sort((a: TSelectOption<number>, b: TSelectOption<number>) =>
      stringComparator(a.label, b.label)
    );
}

type Range = TMinMax & { name: string };

export function handleRanges(ranges: Range[]): TFilterRangeDict {
  if (!ranges) return defaultFilterRanges;

  const result = {};
  ranges.forEach(
    (item: Range) => (result[item.name] = { min: item.min, max: item.max })
  );

  return {
    lengthRange: result['length'],
    powerRange: result['engine-power'],
    tonnageRange: result['gross-tonnage'],
    yearRange: result['build-year']
  };
}
