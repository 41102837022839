import { areasInitialState } from './areas/areasInitialState';
import { campaignInitialState } from './campaign/campaignInitialState';
import { companiesInitialState } from './companies/companiesInitialState';
import { CrmCompaniesInitialState } from './CRM/CrmCompanies/CrmCompaniesInitialState';
import { CrmContactsInitialState } from './CRM/CrmContacts/CrmContactsInitialState';
import { CrmVesselsInitialState } from './CRM/CrmVessels/CrmVesselsInitialState';
import { dictionariesInitialState } from './dictionaries/dictionariesInitialState';
import { eventsInitialState } from './events/events/eventsInitialState';
import { eventEditorInitialState } from './events/eventEditor/eventEditorInitialState';
import { farmsInitialState } from './farm/farmsInitialState';
import { farmsLocationsInitialState } from './map/farmsLocations/farmsLocationsInitialState';
import { filtersInitialState } from './filters/filtersInitialState';
import { fleetsInitialState } from './fleets/fleetsInintialState';
import { loginInitialState } from './login/loginInitialState';
import { mapEntitiesInitialState } from './map/mapEntities/mapEntitiesInitialState';
import { mapOptionsInitialState } from './map/mapOptions/mapOptionsInitialState';
import { miscInitialState } from './misc/miscInitialState';
import { newsInitialState } from './news/news/newsInitialState';
import { newsRecentInitialState } from './news/newsRecent/newsRecentInitialState';
import { notificationsInitialState } from './notifications/notificationsInitialState';
import { playerInitialState } from './player/playerInitialState';
import { productInitialState } from './product/productInitialState';
import { promoInitialState } from './promo/promoInitialState';
import { proposalsInitialState } from './CRM/Proposals/ProposalsInitialState';
import { providerEditorInitialState } from './provider/providerEditor/providerEditorInitialState';
import { providersInitialState } from './provider/providers/providersInitialState';
import { sessionInitialState } from './session/sessionInitialState';
import { searchInitialState } from './search/searchInitialState';
import { tracksInitialState } from './map/tracks/tracksInitialState';
import { trialModalInitialState } from './trialModal/trialModalInitialState';
import { vesselInitialState } from './vessel/vessel/vesselInitialState';
import { vesselsComparatorInitialState } from './vessel/vesselsComparator/vesselsComparatorInitialState';
import { vesselsInitialState } from './vessel/vessels/vesselsInitialState';
import { vesselsLocationsInitialState } from './map/vesselsLocations/vesselsLocationsInitialState';
import { TState } from './appStateModel';

/* eslint-disable */
// @ts-ignore
export const appInitialState: TState = {
  // General section
  companies: companiesInitialState,
  dictionaries: dictionariesInitialState,
  filters: filtersInitialState,
  login: loginInitialState,
  notifications: notificationsInitialState,
  session: sessionInitialState,
  search: searchInitialState,
  misc: miscInitialState,
  // Vessels section
  fleets: fleetsInitialState,
  player: playerInitialState,
  vessel: vesselInitialState,
  vessels: vesselsInitialState,
  vesselsComparator: vesselsComparatorInitialState,
  // News
  news: newsInitialState,
  newsRecent: newsRecentInitialState,
  // Service
  product: productInitialState,
  providerEditor: providerEditorInitialState,
  providers: providersInitialState,
  // Events
  events: eventsInitialState,
  eventEditor: eventEditorInitialState,
  // Map
  mapEntities: mapEntitiesInitialState,
  mapOptions: mapOptionsInitialState,
  tracks: tracksInitialState,
  farmsLocations: farmsLocationsInitialState,
  vesselsLocations: vesselsLocationsInitialState,
  // Promotion & Campaign
  campaign: campaignInitialState,
  promo: promoInitialState,
  // vessel (or client) related
  // contactList: contactListInitialState,
  // vessel: vesselInitialState,
  // CRM
  crmCompanies: CrmCompaniesInitialState,
  crmContacts: CrmContactsInitialState,
  crmVessels: CrmVesselsInitialState,
  proposals: proposalsInitialState,

  // TrialModal
  trialModal: trialModalInitialState,
  // Custom areas:
  areas: areasInitialState,
  // Farms section
  farms: farmsInitialState
};

/* eslint-enable */
