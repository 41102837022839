import React from 'react';
import classnames from 'classnames';

import './AsideTrigger.scss';
import { ActionButton } from '../common/buttons/ActionButton/ActionButton';

/**/
type Props = {
  isOpen: boolean;
  toggleAside: () => void;
};

/**
 *
 */
export function AsideTrigger({ isOpen, toggleAside }: Props) {
  const cls = classnames('AsideTrigger', {
    'AsideTrigger--open': isOpen
  });

  const title = isOpen ? 'Collapse' : 'Expand';

  return (
    <div className={cls}>
      <ActionButton
        className="AsideTrigger__button"
        icon="chevron-right"
        onClick={toggleAside}
        size="XS"
        title={title}
      />
    </div>
  );
}
