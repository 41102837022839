import { TCountryISO } from './dictionaries';
import { TMedia, TVideo } from './media';
import { TPerson } from './person';
import { TProduct } from './product';
import { TVesselOfCompany } from './vessel';
import { TPosition } from './map';

export type TProviderAddressShort = {
  addressId: number;
  branches: Array<TServiceBranch>;
  country: TCountryISO;
  name: string;
  position: TPosition;
  providerId: number;
  title: string;
};

export type TProviderAddress = {
  address: string;
  city: string;
  country: TCountryISO;
  id: number;
  index: number;
  latitude: number;
  longitude: number;
  postcode: string;
  title: string;
};

export type TServiceBranch = {
  id: number;
  media: TMedia;
  parentId: number;
  value: {
    en_GB: string;
  };
};

export type TProviderRights = {
  allowedBranches: number;
  allowedCountries: number;
};

export type TProvider = {
  addresses: TProviderAddress[];
  banner: TMedia;
  branches: TServiceBranch[];
  contacts: TPerson[];
  eventIds: ReadonlyArray<number>;
  id: number;
  logo: TMedia;
  longDescription: string;
  name: string;
  photos: TMedia[];
  products: TProduct[];
  published: boolean;
  settings: TProviderRights;
  shortDescription: string;
  vesselsByCompany?: TVesselOfCompany[];
  videos: TVideo[];
  website: string;
};

export enum EProviderMediaObjectType {
  BANNER = 'BANNER',
  BRANCH = 'BRANCH',
  CONTACT = 'CONTACT',
  EVENT = 'EVENT',
  LOCATION = 'LOCATION',
  LOGO = 'LOGO',
  PRODUCT = 'PRODUCT',
  POSTER = 'POSTER',
  VIDEO = 'VIDEO'
}
