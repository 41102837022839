import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import './IconButton.scss';
import { AppButton } from '../helpers';
import { IconCustom } from 'components/common/IconCustom/IconCustom';

/**/
type Props = Omit<ButtonProps, 'icon' | 'size'> & {
  icon: string;
  size?: 'XS' | 'S';
};

/**
 *
 */
export function IconButton(props: Props) {
  const { icon, ...rest } = props;
  return (
    <AppButton mainClassName="IconButton" type="link" {...rest}>
      <IconCustom className="IconButton__icon" type={icon} />
    </AppButton>
  );
}
