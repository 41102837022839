function parse(q: string): Record<string, string | string[]> {
  const params = new URLSearchParams(q);
  const keys = [...params.keys()];
  const result = {};

  keys.forEach((key: string) => {
    if (key.endsWith('[]')) {
      const _key = decodeURIComponent(key.replace('[]', ''));
      result[_key] = params.getAll(key).map(decodeURIComponent);
    } else {
      result[decodeURIComponent(key)] = params.get(key);
    }
  });

  return result;
}

function build(
  obj: Record<string, string | string[] | number | number[]>
): string {
  let result = '';
  if (Object.keys(obj).length === 0) return result;

  Object.entries(obj).forEach(([key, value]) => {
    const _key = encodeURIComponent(key);

    if (value === null) {
      result += `&${_key}`;
    } else if (Number.isNaN(value)) {
      result += `&${_key}=NaN`;
    } else if (value === void 0) {
      result += `&${_key}=`;
    } else if (Array.isArray(value)) {
      value.forEach((v) => (result += `&${_key}[]=${encodeURIComponent(v)}`));
    } else {
      result += `&${_key}=${encodeURIComponent(value)}`;
    }
  });

  return result.slice(1);
}

const queryString = {
  build: build,
  parse: parse
};

export { queryString };
