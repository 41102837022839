import { crmStatusFinder } from 'pages/CRM/helpers/useTargetEntityLink';
import { ENDPOINTS } from 'other/config';
import { ROUTES } from 'other/constants';

import {
  ESearchCategory,
  EUserAuthority,
  TIdLocationDict,
  TSearchChoice,
  TSearchItem,
  TSearchItemExt,
  TSearchResult,
  TSearchResultExt
} from 'types';

export class SearchUtil {
  private readonly canViewCompanies: boolean;
  private readonly crmCompanyStatusId?: number;
  private readonly crmFarmStatusId?: number;
  private readonly crmVesselStatusId?: number;

  public static getRequestParams(searchQuery: string): string {
    return `${ENDPOINTS.SEARCH}?text=${encodeURIComponent(searchQuery)}`;
  }

  constructor(
    authorities: ReadonlyArray<EUserAuthority>,
    companyStatuses: ReadonlyArray<TIdLocationDict>,
    fishFarmStatuses: ReadonlyArray<TIdLocationDict>,
    vesselStatuses: ReadonlyArray<TIdLocationDict>
  ) {
    this.canViewCompanies = (authorities || []).includes(
      EUserAuthority.VIEW_OWNER
    );
    this.crmCompanyStatusId = companyStatuses.find(crmStatusFinder)?.id;
    this.crmFarmStatusId = fishFarmStatuses.find(crmStatusFinder)?.id;
    this.crmVesselStatusId = vesselStatuses.find(crmStatusFinder)?.id;
  }

  // If user isn't allowed to view companies, remove them from the results.
  public getPayload(results: TSearchResult[]): TSearchResultExt[] {
    if (!results) return [];

    return results
      .filter(
        ({ type }: TSearchResult): boolean =>
          this.canViewCompanies || type.toUpperCase() !== ESearchCategory.PERSON
      )
      .map((data: TSearchResult): TSearchResultExt => {
        const mapper = this.getMapperByType(data.type);
        return SearchUtil.mapItems(data, mapper);
      });
  }

  private getMapperByType(
    type: ESearchCategory
  ): (item: TSearchItem) => TSearchItemExt {
    switch (type) {
      case ESearchCategory.COMPANY:
        return this.mapCompany;
      case ESearchCategory.FARM:
        return this.mapFarm;
      case ESearchCategory.PERSON:
        return this.mapPerson;
      case ESearchCategory.SERVICE_PROVIDER:
        return this.mapService;
      case ESearchCategory.VESSEL:
        return this.mapVessel;
    }
  }

  private static mapItems(
    data: TSearchResult,
    mapper: (item: TSearchItem) => TSearchItemExt
  ): TSearchResultExt {
    const { items, type } = data;
    const mapped = items.map(mapper).filter(Boolean);

    return {
      items: mapped,
      type: type
    };
  }

  private mapCompany = (item: TSearchItem): TSearchItemExt => {
    const { id, statusId, text } = item;

    if (statusId === this.crmCompanyStatusId) {
      return {
        path: `${ROUTES.CRM_COMPANY}/${id}`,
        text: text
      };
    } else if (this.canViewCompanies) {
      return {
        path: `${ROUTES.COMPANY}/${id}`,
        text: text
      };
    }
    return null;
  };

  private mapFarm = (item: TSearchItem): TSearchItemExt => {
    const { id, statusId, text } = item;

    if (this.crmFarmStatusId && this.crmFarmStatusId === statusId) {
      return {
        path: `${ROUTES.CRM_FARM}/${id}`,
        text: text
      };
    }
    return {
      id: id,
      text: text,
      type: ESearchCategory.FARM
    };
  };

  private mapPerson = (item: TSearchItem): TSearchItemExt => {
    const { id, text } = item;
    return {
      path: `${ROUTES.PERSON}/${id}`,
      text: text
    };
  };

  private mapService = (item: TSearchItem): TSearchItemExt => {
    const { id, text } = item;
    return {
      path: `${ROUTES.SERVICE_PROVIDER}/${id}`,
      text: text
    };
  };

  private mapVessel = (item: TSearchItem): TSearchItemExt => {
    const { id, statusId, text } = item;

    if (statusId === this.crmVesselStatusId) {
      return {
        path: `${ROUTES.CRM_VESSEL}/${id}`,
        text: text
      };
    }
    return {
      id: id,
      text: text,
      type: ESearchCategory.VESSEL
    };
  };
}

/**/
export function getItemRoute(item: TSearchChoice): string {
  const { id, path, type } = item;
  if (path) return path;

  return path ? path : `/${type.toLowerCase()}/${id}`;
}
