import { basicInitialState } from 'store/_utils/basicInitialState';
import { TPlayerState } from './playerModel';

export const playerInitialState: TPlayerState = {
  ...basicInitialState,
  cages: null,
  farms: null,
  track: null,
  vessel: null
};
