import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchNotificationsAction,
  markAsReadAction
} from 'store/notifications/notificationActions';
import NotificationMenu from './NotificationMenu';
import { TState } from 'store/appStateModel';

/**
 *
 */
function NotificationButton() {
  const dispatch = useDispatch();

  const { isLoading, isLogged, notifications, total, totalUnread } =
    useSelector((state: TState) => ({
      isLoading: state.notifications.isPending,
      isLogged: !!state.session.user,
      notifications: state.notifications.notifications,
      total: state.notifications.pagination.total,
      totalUnread: state.notifications.totalUnread
    }));

  const props = {
    hasMore: notifications?.length < total,
    isLoading: isLoading,
    notifications,
    totalUnread,
    onClear: () => dispatch(markAsReadAction([])),
    onFetch: (fetchMore?: boolean) =>
      dispatch(fetchNotificationsAction(fetchMore)),
    onItemClick: (id: number) => {
      dispatch(push(String(id)));
      dispatch(markAsReadAction([id]));
    }
  };

  return isLogged && <NotificationMenu {...props} />;
}

/**/
export default NotificationButton;
